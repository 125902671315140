import '../q.css'

import { useEffect, useState } from "react";
import axios from "axios";
import MigationPopup from './Popup/MigationPopup';
import { useNavigate, useParams } from 'react-router-dom';

const Heading = ({icon, text}) => {
    return (
        <div className='s-block-heading'>
            {icon} {text}
        </div>
    )
}

const Button = ({ text, onClick, disabled = false }) => {
    return (<button className='t-copy-btn' disabled={disabled} onClick={onClick}>
         { text }
    </button>)
}

const CopyButton = ({ copyValue, text, activeText = "✅ Скопировано" }) => {

    const [isCopied, setCopied] = useState(false);

    const copyToClipboard = (event) => {
        event.preventDefault();
        navigator.clipboard.writeText(copyValue);
        setCopied(true);
    }

    useEffect(() => {
        if (isCopied) {
            setTimeout(() => {
                setCopied(false);
            }, 3500)
        }
    }, [isCopied])

    return (<Button onClick={copyToClipboard} text={isCopied ? activeText : text}/>)
}

export { Button, Heading, CopyButton };

export default function Key() {

    const { id } = useParams();

    const [pageState, setPageState] = useState('search');
    const [userKeyID, setUserKeyID] = useState('');
    const [prolongationDays, setProlongationDays] = useState(0);

    // States and updates
    const [isCreatingBackupKey, setCreatingBackupKey] = useState(false);
    const [isMigrationOverlayOpened, setMigrationOverlayOpened] = useState(false);
    const [isArchieveingKey, setArchieveingKey] = useState(false);
    const [archieveSafetyCounter, setArchieveSafetyCounter] = useState(0);

    const [isDeletingBackupKey, setDeletingBackupKey] = useState(false);
    const [isProlongationOverlayOpened, setProlongationOverlayOpened] = useState(false);
    const [isProlongationLoading, setProlongationLoading] = useState(false);
    const [databaseKey, setDatabaseKey] = useState(null);

    const navigate = useNavigate();

    const getPlan = (plan) => {
        const n = parseInt(plan);
        if (n === 0) {
            return 'Базовый';
        }
        if (n === 1) {
            return 'Премиум';
        }
        if (n === 2) {
            return 'Deeplink';
        }
        if (n === 3) {
            return 'Семейный 6м';
        }
        if (n === 15) {
            return 'Семейный год';
        }
        if (n === 4) {
            return 'Базовый 🇬🇧';
        }
        if (n === 5) {
            return 'Базовый 🇫🇮';
        }
        if (n === 6) {
            return 'Базовый 🇩🇪';
        }
        if (n === 10) {
            return 'Базовый 🇺🇸';
        }
        if (n === 7) {
            return 'Базовый Yota';
        }
        if (n === 11) {
            return 'Семейный Yota';
        }
        if (n === 8) {
            return 'Семейный 🇬🇧';
        }
        if (n === 9) {
            return 'Семейный 🇫🇮';
        }
        if (n === 13) {
            return 'Семейный 🇩🇪';
        }
        if (n === 14) {
            return 'Семейный 🇺🇸';
        }
        if (n === 12) {
            return 'Russia';
        }
        if (n === 99) {
            return 'Триальный';
        }

		if (n === 20) {
			return 'Я в Китае';
		}
		if (n === 21) {
			return 'Я в Китае / 6м';
		}

        return '';
    };

    const formatDate = (dateObject) => {
        return ("0" + dateObject.getDate()).slice(-2) + "." +
        ("0" + (dateObject.getMonth() + 1)).slice(-2) + "." +
        dateObject.getFullYear();
    }

    const makeOutlineBackupKey = async () => {
        const data = {
            key: databaseKey.id,
        };
        console.log({ data });
        try {
            setCreatingBackupKey(true);
            const req = await axios.post('https://dusk.masliukov.com/v4/backupOutlineKey', data);
            if (req.data.ok) {
                searchKey();
            } else {
                alert('Something went wrong');
            }
            setCreatingBackupKey(false);
        } catch (e) {
            console.log({ e });
        }
    };

    const deleteOutlineKey = async () => {
        // deleteOutlineKey
        const data = {
            key: databaseKey.id,
        };
        console.log({ data });
        try {
            setDeletingBackupKey(true);
            const req = await axios.post('https://dusk.masliukov.com/v4/deleteOutlineKey', data);
            if (req.data.ok) {
                searchKey();
            } else {
                alert('Something went wrong');
            }
            setDeletingBackupKey(false);
        } catch (e) {
            console.log({ e });
        }
    }

    const archieveKey = async () => {
        if(isArchieveingKey){return}
        try {
            let _id = userKeyID
            if (_id === '' || _id === undefined || _id === null) {
                _id = id
            }
            setArchieveingKey(true)

            const req = await axios.post("https://dusk.masliukov.com/v4/archive-user-key", { id: _id });
            if (req.data.ok) {
                await searchKey();
            } else {
                alert('Something went wrong');
            }
            setArchieveingKey(false);
            setArchieveSafetyCounter(0);
        } catch (e) {
            console.log(e);
            alert("Something went wrong");
        }
    }

    const clearInformation = () => {
        window.location = '/key';
    }

    useEffect(() => {
        if (archieveSafetyCounter === 5) {
            archieveKey();
        }
    }, [archieveSafetyCounter])

    useEffect(() => {
        if (id !== null && id !== undefined) {
            if (id.length > 0) {    
                searchKeyByID(id)   
            }
        }
    }, [id])

    const searchKey = async () => {
        console.log({ userKeyID })
        let _id = userKeyID
        if (_id === '' || _id === undefined || _id === null) {
            _id = id
        }
        const request = await axios.post('https://dusk.masliukov.com/v4/get-user-key', { id: _id });
        const { data } = request;
        console.log({data})
        const { key } = data;
        setDatabaseKey(key);
        setPageState('response');
    }

    const searchKeyByID = async (localeID) => {
        const request = await axios.post('https://dusk.masliukov.com/v4/get-user-key', { id: localeID });
        const { data } = request;console.log({data})
        const { key } = data;
        setDatabaseKey(key);
        setPageState('response');
    }

    const prolongateKey = async () => {
        if (parseInt(prolongationDays) < 1) {
            return;
        }

        setProlongationLoading(true);
        const data = {
            key: databaseKey.id,
            days: prolongationDays,
        };

        try {
            const req = await axios.post("https://dusk.masliukov.com/v4/prolongateKey", data);
            if (req.data.ok) {
                await searchKey();
            } else {
                alert('Something went wrong');
            }
            setProlongationDays(0);
            setProlongationLoading(false);
            setProlongationOverlayOpened(false);
        } catch (e) {
            console.log(e);
            alert("Something went wrong");
        }
    }


    const validateUserKey = (event) => {
        if (event.target.value === '') {
            return;
        }
        let key = String(event.target.value);
        let keyId = '';

        if (String(key).includes('?q=')) {
            const array = key.split('?q=');
            key = array[0];
        }

        if (String(key).includes('https://key.simplesmm.org/')) {
            const validKey = String(key);
            keyId = validKey.split('https://key.simplesmm.org/')[1];
            setUserKeyID(keyId);
        }
    };

    if (pageState === 'response') {
        return (
            <>
                
                {isMigrationOverlayOpened && <MigationPopup
                    keyID={databaseKey.id}
                    close={() => setMigrationOverlayOpened(false)}
                    reload={() => {
                    searchKey();
                    setMigrationOverlayOpened(false);
                }}/>}

                {isProlongationOverlayOpened && (<div className='overlay'>
                    <div className='overlay-content'>
                        <div>Продляем ключ на количество дней</div>
                        <input type='number' className='t-input' value={prolongationDays} min={0} onChange={e => setProlongationDays(e.target.value)} placeholder='На сколько дней?'></input>
                        <button className='t-button' disabled={isProlongationLoading} onClick={prolongateKey}>{isProlongationLoading ? '⏳ Изменяем ход времени...' : "Продлить"}</button>
                        <button className='t-button' onClick={() => setProlongationOverlayOpened(false)}>Закрыть</button>
                    </div>
                </div>)}
            <div className="content-block t-container">
            <div className="t-content split" style={{paddingBottom: '120px'}}>
                <div className="page-header">
                    <h1>Ключ пользователя</h1>
                </div>
                <Heading icon={'🔑'} text={"Ключ"}/>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '4px', flexWrap: 'wrap' }}>
                    <span className='outline-key-span'>{getPlan(databaseKey.plan)}</span>
                    <span className="outline-key-span">{databaseKey.id}</span>
                    <CopyButton text={'✏️ Ссылка'} copyValue={`https://key.simplesmm.org/${databaseKey.id}?q=${getPlan(databaseKey.plan)}`} />
                    <CopyButton text={'✏️ Конфигурация'} copyValue={`ssconf://c.simplesmm.org/${databaseKey.id}#SimpleVPN / ${getPlan(databaseKey.plan)}`} />
                    {
                        databaseKey.outline_backup_link === null &&
                                <Button text={!isCreatingBackupKey ? "Создать статический ключ" : "⏳ Создаем ключ..."} disabled={isCreatingBackupKey} onClick={makeOutlineBackupKey} />}
                            {<Button
                                disabled={isArchieveingKey}
                                onClick={() => {
                                    if (archieveSafetyCounter === 5) {
                                        return;
                                    }
                                    setArchieveSafetyCounter(archieveSafetyCounter + 1);
                                }}
                                text={isArchieveingKey ? "⏳ Архивируем ключ..." : archieveSafetyCounter === 0 ? "⚠️ Архивировать ключ" : `🚨 Нажмите ${5 - archieveSafetyCounter} раз`} />}
                </div>
                {databaseKey.outline_backup_link !== null && <>
                    <Heading icon={'🛠️'} text={'Статический ключ ( Старая версия )'} />
                    <div style={{display: 'flex', gap: '4px'}}>
                        <CopyButton text={'Ссылка'} copyValue={databaseKey.outline_backup_link} />
                        <Button text={isDeletingBackupKey ? "⏳ Удаляем..." : "Удалить статический ключ"} disabled={isDeletingBackupKey} onClick={deleteOutlineKey}/>
                    </div>
                </>}
                <Heading icon={'⏰'} text={'Действует до'} />
                <div style={{display: 'flex', gap: '4px'}}>
                    <span className="outline-key-span">{formatDate(new Date(databaseKey.expire_date))}</span>
                    <Button disabled={false} text={"Продлить"} onClick={() => setProlongationOverlayOpened(true)}/>
                </div>
                <Heading icon={'🧍🏻'} text={'Пользователь'}/>
                <div style={{display: 'flex', gap: '4px', flexWrap: 'wrap'}}>
                    <span className="outline-key-span">@{databaseKey.username} / {databaseKey.user_id}</span>
                            <Button disabled={false} onClick={() => {
                                 navigate('/user/'+databaseKey.user_id)
                    }} text={"Открыть профиль"}/>
                </div>
                <Heading icon={'🕸️'} text={'Сервер'} />
                        <div className='t-text-warning'>⚠️ Если у пользователя при миграции был статический ключ - удалите его и создайте заного</div>
                        {databaseKey.is_proxy_enabled && <div className='outline-proxy-span'>🧭 {databaseKey.proxy_ip} {databaseKey.proxy_port !== '0' && databaseKey.proxy_port !== '0' && <>:{ databaseKey.proxy_port}</>}</div>}
                        <div style={{ display: 'flex', gap: '4px' }}>
                    <div className='outline-key-span'>{databaseKey.configuration.server}:{databaseKey.configuration.server_port}</div>
                    <Button onClick={() => setMigrationOverlayOpened(true)} text={"Миграция"}/>
                        </div>
                        <div style={{paddingBottom: '120px'}}>
                            <Button disabled={false} text={"❌ Закрыть"} onClick={() => { clearInformation() }} />
                            </div>
            </div>
        </div></>)
    }

    if (pageState === 'search') {
        return <>
            <div className="content-block t-container">
            <div className="t-content split">
                <div className="page-header">
                    <h1>Ключ пользователя</h1>
                    </div>
                    <input
                        value={userKeyID}
                        placeholder="https://key.simplesmm.org/XXXX"
                        onChange={validateUserKey}
                        type="text"
                        className="t-input"
                    ></input>
                    <button
                    className="t-button"
                    onClick={(e) => {
                        e.preventDefault();
                        searchKey();
                    }}
                >
                    Найти ключ
                </button>
            </div>
        </div>
        </>
    }

}