import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DeeplinkRow from './DeeplinkRow';
import DeeplinkOverlay from '../components/DeeplinkOverlay';

export default function CreateDeeplink() {
	const API_URL = 'https://dusk.masliukov.com/v2-api/deeplink/';
	const [deeplinks, setDeeplinks] = useState([]);
	const [isCreation, setCreation] = useState(false);

	useEffect(() => {
		loadDeeplinks();
	}, []);

	const loadDeeplinks = async () => {
		const request = await axios.post(API_URL + 'getList');
		setDeeplinks(request.data.list);
	};

	return (
		<div className='content-block t-container'>
			<div className='t-content'>
				<div className='page-header'>
					<h1>Deeplinks</h1>
					<div className='header-buttons-group'>
						<button className='t-button' onClick={(e) => setCreation(true)}>
							Создать
						</button>
					</div>
				</div>
			</div>

			{isCreation && <DeeplinkOverlay loadDeeplinks={loadDeeplinks} close={() => setCreation(false)} />}
			<div className='row-deeplink labels'>
				<div className='deeplink-column'>Статус</div>
				<div className='deeplink-column'>Пользователь</div>
				<div className='deeplink-column'>Ключ</div>
				<div className='deeplink-column'>Срок</div>
				<div className='deeplink-column'>Тип сервера</div>
				<div className='deeplink-column'>Ссылка</div>
			</div>
			{deeplinks.map((deeplink) => {
				return <DeeplinkRow type='outline' deeplink={deeplink} />;
			})}
		</div>
	);
}
