import React, { useEffect, useState } from 'react';

export default function Login({ handleLogin }) {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	useEffect(() => {
		const savedEmail = localStorage.getItem('simple_vpn_saved_email');
		if (savedEmail !== null && savedEmail !== undefined) {
			setEmail(savedEmail);
		}
	}, []);

	const handleAuth = async (event) => {
		event.preventDefault();
		if (email === null || email === undefined || email.length === 0 || password === null || password === undefined || password.length === 0) {
			return alert('Fill fields');
		}
		handleLogin({ email: email, password: password });
	};

	return (
		<div className='t-container t-container-center'>
			<form onSubmit={handleAuth} className='t-content t-content-center'>
				<input type='email' placeholder='Email' value={email} className='t-input' onChange={(e) => setEmail(e.target.value.trim())} />
				<input
					type='password'
					placeholder='Password'
					value={password}
					className='t-input'
					onChange={(e) => setPassword(e.target.value.trim())}
				/>
				<button className='t-button t-primary t-wide' type='submit'>
					Login
				</button>
			</form>
		</div>
	);
}
