import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function ProfileSearch() {

    const [username, setUsername] = useState('');
    const [chatId, setChatId] = useState('');
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    const searchUserEmail = async () => {

        const cleanedEmail = email.trim().toLowerCase();
        
        const data = { email: cleanedEmail }
        console.log({ data });
        const requestURL = "https://dusk.masliukov.com/v4/get-id-by-email";
        const request = await axios.post(requestURL, data);
        console.log({ request });
        const { ok, id } = request.data;
        
        if (!ok) {
            alert("User not found");
        } else {
            navigate('/user/' + id);
        }
    }

    const searchUser = async () => {
        let cleanedUsername = username;
        if (cleanedUsername.includes('@')) {
            cleanedUsername =  cleanedUsername.replace('@', '');
        }

        cleanedUsername = cleanedUsername.trim();
        
        const data = { username: cleanedUsername }
        console.log({ data });
        const requestURL = "https://dusk.masliukov.com/v4/get-id-by-username";
        const request = await axios.post(requestURL, data);
        console.log({ request });
        const { ok, id } = request.data;
        
        if (!ok) {
            alert("User not found");
        } else {
            navigate('/user/' + id);
        }
    }

    const handleEmailChange = (event) => {
        event.preventDefault();
        const {value} = event.target
        setEmail(String(value).replaceAll('mailto:', ''));
    }

  return (
    <>
            <div className="content-block t-container">
            <div className="t-content split">
                <div className="page-header">
                    <h1>Поиск пользователя</h1>
                    </div>
                    <input
                        value={username}
                        placeholder="@username"
                        onChange={e => setUsername(e.target.value)}
                        type="text"
                        className="t-input"
                    ></input>
                    <button
                    className="t-button"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        searchUser();
                    }}
                >
                    Найти пользователя
                  </button>
                  <div className="page-header">
                    <h1>Поиск пользователя по email</h1>
                    </div>
                    <input
                        value={email}
                        placeholder="Email"
                        onChange={handleEmailChange}
                        type="text"
                        className="t-input"
                    ></input>
                    <button
                    className="t-button"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        searchUserEmail();
                    }}
                >
                    Найти пользователя
                  </button>
                  <div className="page-header">
                    <h1>Поиск пользователя по chat_id</h1>
                    </div>
                    <input
                        value={chatId}
                        placeholder="Chat_ID"
                        onChange={e => setChatId(e.target.value)}
                        type="text"
                        className="t-input"
                    ></input>
                    <button
                    className="t-button"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        window.location = '/user/' + chatId;
                    }}
                >
                    Найти пользователя
                </button>
            </div>
        </div>
        </>
  )
}