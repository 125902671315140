import React, { useEffect, useState } from 'react';
import axios from 'axios';
export default function TableOrders() {
  const [table, setTable] = useState([]);
  const URL = 'https://dusk.masliukov.com/v2-api/table-orders/';
  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    const req = await axios.post(URL);
    // // console.log(req.data.table);

    let arr = req.data.table;
    // console.log(arr);
    setTable(req.data.table);
  };

  return (
    <div className="content-block">
      <h1>Таблица заказов</h1>

      <table className="orders-table">
        <tr className="orders-row">
          <th className="orders-column orders-100">chat_id</th>
          <th className="orders-column orders-300">order_id</th>
          <th className="orders-column orders-100">price</th>
          <th className="orders-column orders-100">plan</th>
          <th className="orders-column orders-200">order_date</th>
          <th className="orders-column orders-200">expire_date</th>
          <th className="orders-column orders-100">is_paid</th>
          <th className="orders-column orders-200">qiwi_id</th>
          <th className="orders-column orders-300">qiwi_key</th>
          <th className="orders-column orders-100">vpn_key_id</th>
          <th className="orders-column orders-500">vpn_server</th>
          <th className="orders-column orders-200">vpn_server_id</th>
          <th className="orders-column orders-200">vpn_user_key</th>
          <th className="orders-column orders-500">payment_link</th>
          <th className="orders-column orders-100">public_id</th>
          <th className="orders-column orders-500">outline_link</th>
        </tr>
        {table.map((row) => {
          return (
            <tr className="orders-row">
              <td className="orders-column orders-100">{row.chat_id}</td>
              <td className="orders-column orders-300">{row.order_id}</td>
              <td className="orders-column orders-100">{row.price}</td>
              <td className="orders-column orders-100">{row.plan}</td>
              <td className="orders-column orders-200">{row.order_date}</td>
              <td className="orders-column orders-200">{row.expire_date}</td>
              <td className="orders-column orders-100">{row.is_paid}</td>
              <td className="orders-column orders-200">{row.qiwi_id}</td>
              <td className="orders-column orders-300">{row.qiwi_key}</td>
              <td className="orders-column orders-100">{row.vpn_key_id}</td>
              <td className="orders-column orders-500">{row.vpn_server}</td>
              <td className="orders-column orders-200">{row.vpn_server_id}</td>
              <td className="orders-column orders-200">{row.vpn_user_key}</td>
              <td className="orders-column orders-500">{row.payment_link}</td>
              <td className="orders-column orders-100">{row.public_id}</td>
              <td className="orders-column orders-500">{row.outline_link}</td>
            </tr>
          );
        })}
      </table>
    </div>
  );
}
