import React, { useEffect, useState } from 'react';
import './campaign.css';
export default function CampaignRow({
  openCampaign,
  is_active,
  promo_name,
  promo_id,
  expire_date,
  new_users,
  bought_users,
  profit,
  discount,
  created_at,
  free_days,
}) {
  const [isCopied, setCopied] = useState(false);

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setCopied(false);
      }, 4000);
    }
  }, [isCopied]);

  const copyToClipboard = (event) => {
    event.preventDefault();
    let key = event.currentTarget.dataset.key;
    navigator.clipboard.writeText('https://t.me/simple_vpn_bot?start=' + key);
    setCopied(true);
  };

  return (
    <div className="campaign-container focused" onClick={() => openCampaign(promo_id)}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div className={is_active ? 'status-round green' : 'status-round yellow'}></div>
      </div>
      <div className="campaign-name-container">
        <span className="campaign-id">
          #{promo_id}{' '}
          <span>
            {new Date(created_at).toLocaleDateString()}-{new Date(expire_date).toLocaleDateString()}{' '}
          </span>
        </span>
        <span className="campaign-name">{promo_name}</span>
      </div>
      <div style={{ display: 'flex', gap: '8px', textAlign: 'center', whiteSpace: 'nowrap' }}>
        <div style={{ width: '70px' }}>
          {new_users} / {bought_users}
        </div>{' '}
        <span className="span">{bought_users > 0 ? ((bought_users / new_users) * 100).toFixed(2) : 0}% CR</span>
      </div>
      <div style={{ textAlign: 'center' }}>{profit}₽</div>
      <div style={{ whiteSpace: 'no-wrap', display: 'flex', textAlign: 'center', whiteSpace: 'nowrap' }}>
        {profit > 0 && <>{(profit / new_users).toFixed(2)}₽</>} / {bought_users > 0 && <>{(profit / bought_users).toFixed(2)}₽</>}
      </div>
      <div style={{ textAlign: 'center' }}>{free_days}</div>
      <div style={{ textAlign: 'center' }}>{discount}%</div>
    </div>
  );
}
