import './common.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import SettingsQiwi from './pages/SettingsQiwi';
import SettingsOutline from './pages/SettingsOutline';
import CreateDeeplink from './pages/CreateDeeplink';
import Statistics from './pages/Statistics';
import CreatePromo from './pages/CreatePromo';
import Login from './pages/Login';
import TableOrders from './pages/TableOrders';
import axios from 'axios';
import SendMessage from './pages/SendMessage';
import Navigation from './pages/Navigation';
import Migration from './pages/Migration';

import Key from './pages/Key';
import Profile from './pages/Profile/view';
import Proxies from './pages/Proxies/Proxies';
import ProfileSearch from './pages/ProfileSearch/ProfileSearch';
import SettingsVless from './pages/SettingsVless';
import KeyVless from './pages/KeyVless/KeyVless';
import VlessMigration from './pages/VlessMigration';
import CreateDeeplinkVless from './pages/CreateDeeplinkVless';
function App() {
	const [isVerifying, setVerifying] = useState(true);
	const [isLoggedIn, setLoggedIn] = useState(false);
	const [status, setStatus] = useState('');

	useEffect(() => {
		verifyLogin();
	}, []);

	const verifyLogin = async () => {
		const token = localStorage.getItem('simple_vpn_token');
		if (!token) {
			setLoggedIn(false);
			setVerifying(false);
			return;
		}
		if (token.length === 0) {
			localStorage.removeItem('simple_vpn_token');
			setLoggedIn(false);
			setVerifying(false);
			return;
		}
		try {
			const req = await axios.post(
				'https://dusk.masliukov.com/v4-api/verifyPerson/',
				{},
				{
					headers: {
						authorization: `Bearer: ${token}`,
					},
				}
			);
			if (req.data.ok) {
				setLoggedIn(true);
				setStatus(req.data.status);
			}
			setVerifying(false);
		} catch (e) {
			console.log('Failed auth');
			localStorage.clear();
		}
		setVerifying(false);
	};

	const handleLogin = async ({ email, password }) => {
		const req = await axios.post('https://dusk.masliukov.com/v4-api/login/', { email, password });
		console.log({ data: req.data });
		if (req.data.ok === true) {
			setStatus(req.data.status);
			localStorage.setItem('simple_vpn_saved_email', email);
			localStorage.setItem('simple_vpn_token', req.data.token);
			localStorage.setItem('secret_model', req.data.status);
			setLoggedIn(true);
		} else {
			alert(req.data.message);
		}
	};

	if (isVerifying) {
		return <div className='t-container'></div>;
	}
	if (isLoggedIn === null) {
		return <div className='t-container'></div>;
	}

	if (isLoggedIn === false) {
		return <Login handleLogin={handleLogin} />;
	}
	return (
		<BrowserRouter>
			<Navigation status={status} />
			<div className='common-container'>
				<div className='common-content'>
					{status === 'admin' && (
						<Routes>
							<Route path='/' element={<Statistics />} />
							<Route path='/key' element={<Key />} />
							<Route path='/vless-key' element={<KeyVless />} />
							<Route path='/vless-key/:id' element={<KeyVless />} />
							<Route path='/user' element={<ProfileSearch />} />
							<Route path='/create-deeplink' element={<CreateDeeplink />} />
							<Route path='/create-deeplink-vless' element={<CreateDeeplinkVless />} />
							<Route path='/campaigns' element={<CreatePromo />} />
							<Route path='/settings-qiwi' element={<SettingsQiwi />} />
							<Route path='/settings-outline' element={<SettingsOutline />} />
							<Route path='/settings-vless' element={<SettingsVless />} />
							<Route path='/table-orders' element={<TableOrders />} />
							<Route path='/send-message' element={<SendMessage />} />
							<Route path='/migration' element={<Migration />} />
							<Route path='/migration-vless' element={<VlessMigration />} />
							<Route path='/user/:id' element={<Profile />} />
							<Route path='/key/:id' element={<Key />} />
							<Route path='/proxies' element={<Proxies />} />
						</Routes>
					)}
					{status === 'moderator' && (
						<Routes>
							<Route path='/' element={<CreatePromo />} />
							<Route path='/user' element={<ProfileSearch />} />
							<Route path='/key' element={<Key />} />
							<Route path='/vless-key' element={<KeyVless />} />
							<Route path='/vless-key/:id' element={<KeyVless />} />
							<Route path='/create-deeplink' element={<CreateDeeplink />} />
							<Route path='/create-deeplink-vless' element={<CreateDeeplinkVless />} />
							<Route path='/campaigns' element={<CreatePromo />} />
							<Route path='/send-message' element={<SendMessage />} />
							<Route path='/migration' element={<Migration />} />
							<Route path='/migration-vless' element={<VlessMigration />} />
							<Route path='/user/:id' element={<Profile />} />
							<Route path='/key/:id' element={<Key />} />
						</Routes>
					)}
					{status === 'system-manager' && (
						<Routes>
							<Route path='/' element={<></>} />
							<Route path='/key' element={<Key />} />
							<Route path='/vless-key' element={<KeyVless />} />
							<Route path='/vless-key/:id' element={<KeyVless />} />
							<Route path='/user' element={<ProfileSearch />} />
							<Route path='/create-deeplink' element={<CreateDeeplink />} />
							<Route path='/create-deeplink-vless' element={<CreateDeeplinkVless />} />
							<Route path='/campaigns' element={<CreatePromo />} />
							<Route path='/settings-qiwi' element={<SettingsQiwi />} />
							<Route path='/settings-vless' element={<SettingsVless />} />
							<Route path='/settings-outline' element={<SettingsOutline />} />
							<Route path='/table-orders' element={<TableOrders />} />
							<Route path='/send-message' element={<SendMessage />} />
							<Route path='/migration' element={<Migration />} />
							<Route path='/migration-vless' element={<VlessMigration />} />
							<Route path='/user/:id' element={<Profile />} />
							<Route path='/key/:id' element={<Key />} />
							<Route path='/proxies' element={<Proxies />} />
						</Routes>
					)}
				</div>
			</div>
		</BrowserRouter>
	);
}

export default App;
