import { useState } from 'react';

import axios from 'axios';
import Loader from './helpers/Loader';

export default function ServerCreationOverlayVless({ close, getList }) {
	const [newKey, setNewKey] = useState('');
	const [newPlan, setPlan] = useState(0);

	const [url, setURL] = useState('');

	const [keyURL, setKeyURL] = useState('');
	const [login, setLogin] = useState('');
	const [password, setPassword] = useState('');
	const [inboundId, setInboundId] = useState('1');

	const API_URL = 'https://dusk.masliukov.com/v2-api/vless/';
	const [isLoading, setLoading] = useState(false);
	const createKey = async () => {
		setLoading(true);
		const CALL_URL = API_URL + 'createKey';
		// login, password, inbound_id, plan
		const data = {
			url: url,
			key_url: keyURL,
			login: login,
			password: password,
			inbound_id: inboundId,
			plan: newPlan,
		};
		const request = await axios.post(CALL_URL, data);
		if (request.data.ok === true) {
			if (request.data.success === false) {
				alert('Something wrong with credentials or Vless server setup...');
				setLoading(false);
				return;
			}
			getList();
			setLoading(false);
			close();
		}
	};

	const validateKey = (string = '') => {
		const cleanedString = string.trim();
		setNewKey(cleanedString);
	};

	const handleClose = (event) => {
		const { id } = event.target;
		if (id === 'overlay') {
			close();
		}
	};

	return (
		<div className='overlay' id='overlay' onClick={handleClose}>
			<div className='overlay-content overlay-content-server'>
				<div>Добавить сервер</div>
				<input
					className='t-input'
					placeholder='URL админки с портом без последнего /'
					type='text'
					value={url}
					onChange={(e) => setURL(e.target.value.trim())}
				></input>
				<input
					className='t-input'
					placeholder='URL ключей с портом без последнего /'
					type='text'
					value={keyURL}
					onChange={(e) => setKeyURL(e.target.value.trim())}
				></input>

				<input className='t-input' placeholder='Login' type='text' value={login} onChange={(e) => setLogin(e.target.value.trim())}></input>
				<input
					className='t-input'
					placeholder='Password'
					type='text'
					value={password}
					onChange={(e) => setPassword(e.target.value.trim())}
				></input>
				<input
					className='t-input'
					placeholder='InboundID'
					type='text'
					value={inboundId}
					onChange={(e) => setInboundId(e.target.value.trim())}
				></input>

				<select className='t-input' type='text' value={newPlan} onChange={(e) => setPlan(e.target.value)}>
					<option value={0}>Base</option>
					<option value={1}>Premium</option>
					<option value={2}>Deeplink</option>
					<option value={3}>Family</option>
					<option value={15}>Family 1y</option>
					<option value={4}>1 Month / UK 🇬🇧</option>
					<option value={5}>1 Month / Finland 🇫🇮</option>
					<option value={6}>1 Month / Germany 🇩🇪</option>
					<option value={10}>1 Month / USA 🇺🇸</option>
					<option value={12}>1 Month / Rus 🇷🇺</option>
					<option value={7}>1 Month / Yota, Tele2</option>
					<option value={8}>6 Month / 🇬🇧 UK</option>
					<option value={9}>6 Month / 🇫🇮 Finland</option>
					<option value={13}>6 Month / 🇩🇪 Germany</option>
					<option value={14}>6 Month / 🇺🇸 USA</option>
					<option value={11}>6 Month / Yota, Tele2</option>
					<option value={20}>1 Month / Я в Китае 🇨🇳</option>
					<option value={21}>6 Month / Я в Китае 🇨🇳</option>
					<option value={99}>Trial (.99)</option>
				</select>
				<button onClick={createKey} className='t-button t-primary'>
					{isLoading ? <Loader /> : 'Добавить'}
				</button>
			</div>
		</div>
	);
}
