import axios from 'axios';
import { useState, useEffect } from 'react';

export default function ServerRow({ server, openServer, type = 'shadowsocks' }) {
	const [onlineCount, setOnlineCount] = useState(null);
	useEffect(() => {
		if (type === 'vless') {
			getOnline();
		}
	}, []);

	const getOnline = async () => {
		const request = await axios.post('https://dusk.masliukov.com/v2-api/vless/getOnline', { id: server.id });
		const { online } = request.data;
		setOnlineCount(online);
	};
	const getPlan = (num) => {
		const n = parseInt(num);

		if (n === 0) {
			return 'Base';
		} else if (n === 1) {
			return 'Premium';
		} else if (n === 2) {
			return 'Deeplink';
		} else if (n === 3) {
			return 'Family';
		} else if (n === 4) {
			return '1m / 🇬🇧';
		} else if (n === 5) {
			return '1m / 🇫🇮';
		} else if (n === 6) {
			return '1m / 🇩🇪';
		} else if (n === 7) {
			return '1m / Yota';
		} else if (n === 8) {
			return '6m / 🇬🇧';
		} else if (n === 9) {
			return '6m / 🇫🇮';
		} else if (n === 10) {
			return '1m / 🇺🇸';
		} else if (n === 11) {
			return '6m / Yota';
		} else if (n === 12) {
			return '1m / 🇷🇺';
		} else if (n === 13) {
			return '6m / 🇩🇪';
		} else if (n === 14) {
			return '6m / 🇺🇸';
		} else if (n === 15) {
			return 'Family 1y';
		} else if (n === 99) {
			return 'Trials (99)';
		}

		if (n === 20) {
			return 'Я в Китае';
		}
		if (n === 21) {
			return '6м / Я в Китае';
		}
	};

	return (
		<div className='row-qiwi' onClick={() => openServer(server.id)}>
			<div className='qiwi-column'>
				<div
					className={
						server.is_proxy_enabled
							? server.enabled
								? 'status-round purple'
								: 'status-round orange'
							: server.enabled
							? 'status-round green'
							: 'status-round red'
					}
				></div>
			</div>
			<div className='qiwi-column'>{getPlan(server.plan)}</div>
			<div className='qiwi-column'>{server.id}</div>
			<div className='qiwi-column'>{server.comment}</div>
			<div className='qiwi-column wallet-hidden'>
				{server.api} {server.url}
			</div>
			<div className='qiwi-column'>
				{server.amountofusers} / {server.user_limit}
				{onlineCount !== null && <> | {onlineCount}</>}
			</div>
		</div>
	);
}
