import { useEffect, useState } from 'react';
import '../q.css'
import { useParams } from "react-router-dom"
import axios from 'axios';
import { Button, CopyButton, Heading } from '../Key';


const KeyRow = ({type = 'outline', keyInformation}) => {
        const getPlan = (num) => {
            const n = parseInt(num);
    
            if (n === 0) {
                return 'Base';
            } else if (n === 1) {
                return 'Premium';
            } else if (n === 2) {
                return 'Deeplink';
            } else if (n === 3) {
                return 'Family';
            } else if (n === 4) {
                return '1m / 🇬🇧';
            } else if (n === 5) {
                return '1m / 🇫🇮';
            } else if (n === 6) {
                return '1m / 🇩🇪';
            } else if (n === 7) {
                return '1m / Yota';
            } else if (n === 8) {
                return '6m / 🇬🇧';
            } else if (n === 9) {
                return '6m / 🇫🇮';
            } else if (n === 10) {
                return '1m / 🇺🇸';
            } else if (n === 11) {
                return '6m / Yota';
            } else if (n === 12) {
                return '1m / 🇷🇺';
            } else if (n === 13) {
                return '6m / 🇩🇪';
            } else if (n === 14) {
                return '6m / 🇺🇸';
            } else if (n === 15) {
                return 'Family 1y';
            } else if (n === 99) {
                return 'Trials (99)';
            }

            if (n === 20) {
                return 'Я в Китае';
            }
            if (n === 21) {
                return 'Я в Китае / 6м';
            }

        };
    
    const openKey = (id) => {
        if (type === 'vless') {
            window.open('/vless-key/' + id)
        } else {
            window.open("/key/" + id);   
        }
    }

        return (
            <div className="row-qiwi" onClick={() => openKey(keyInformation.id)}>
                <div className="qiwi-column">
                    <div className={(!keyInformation.is_archieved && keyInformation.is_active) ? 'status-round green' : 'status-round red'}></div>
                </div>
                <div className="qiwi-column">{getPlan(keyInformation.plan)}</div>
                <div className="qiwi-column">{keyInformation.configuration.server}</div>
                <div className="qiwi-column">{new Date(keyInformation.expire_date).toLocaleDateString()}</div>
                <div className="qiwi-column wallet-hidden">{(keyInformation.id)}</div>
                <div className="qiwi-column">
                </div>
            </div>
        );
}

const OrderRow = ({ order }) => {
    return <div className="row-qiwi">
        <div className="qiwi-column">
                    <div className={(order.is_paid) ? 'status-round green' : 'status-round red'}></div>
                </div>
                <div className="qiwi-column">{ order.price === 0 ? 'Deeplink' :  <>{order.price} ₽</>}</div>
                {/* <div className="qiwi-column">{keyInformation.configuration.server}</div> */}
                <div className="qiwi-column">{new Date(order.order_date).toLocaleDateString()}</div>
                {/* <div className="qiwi-column wallet-hidden">{(keyInformation.id)}</div> */}
                <div className="qiwi-column">
                </div>
    </div>
}

export default function Profile() {
    
    
    const { id } = useParams();

    const [user, setUser] = useState(null);
    const [userKeys, setUserKeys] = useState(null);
    const [userVlessKeys, setUserVlessKeys] = useState(null);
    const [userOrders, setUserOrders] = useState(null);
    const [webUser, setWebUser] = useState(null);
    const [recoveredPassword, setRecoveredPassword] = useState(null);
    const [isEmailEditOpen, setEmailEditOpen] = useState(false);
    const [editedEmail, setEditedEmail] = useState('');


    useEffect(() => {
        fetchUser();
    }, [])

    const fetchUser = async () => {
        const request = await axios.post('https://dusk.masliukov.com/v4/get-user', { id: id });
        const { data } = request;
        console.log({ data })
        if (data.web) {
            setWebUser(data.web);
        }
        setUser(data.user);
        setUserKeys(data.keys);
        setUserVlessKeys(data.vlessKeys);
        setUserOrders(data.orders);
        console.log(data)
    }

    const handleWebPasswordRecovery = async (event) => {
        event.preventDefault();
        setRecoveredPassword(null);
        const requestURL = 'https://dusk.masliukov.com/v5/recoverPassword'
        const requestData = {
            id: webUser.id
        }
        console.log({requestData})
        const req = await axios.post(requestURL, requestData);
        const { data } = req;
        console.log(data);
        if (data.ok) {
            const { password } = data;
            setRecoveredPassword(password);
        }
    }

    const editEmail = async (event) => {
        event.preventDefault();
        setEditedEmail(webUser.email)
        setEmailEditOpen(true);
    }

    const updateUserEmail = async (event) => {
        event.preventDefault();
        
        const requestURL = 'https://dusk.masliukov.com/v5/editEmail'
        const requestData = {
            id: webUser.id,
            email: editedEmail.toLowerCase().trim()
        }
        setEmailEditOpen(false);
        const request = await axios.post(requestURL, requestData);
        const { ok } = request.data;
        if (ok) {
            alert("Успешно обновлен")
            fetchUser();
        } else {
            alert('Что-то пошло не так');
        }
    }


    if (user === null) {
        return <div className="content-block t-container">
        <div className="t-content split">
            <div className="page-header">Загрузка...</div></div></div>
    }

  return (
      <>
          {isEmailEditOpen && <div className='overlay' id='overlay' onClick={(e) => {
              const id = e.target.id;
              if (id === 'overlay') {
                  setEmailEditOpen(false);
              }
          }}>
              <form onSubmit={updateUserEmail} className='overlay-content'>
                  <div>
                      Обновить email пользователя
                  </div>
                  <input type='email' required className='t-input' value={editedEmail} onChange={e => setEditedEmail(e.target.value)} />
                  <button type='submit' className='t-button'>Обновить</button>
            </form>
          </div>}
    <div className="content-block t-container">
    <div className="t-content split">
        <div className="page-header">
                  <h1>Пользователь / {id}</h1></div>
              <Heading icon={'🫅🏻'} text={"Информация"} />
              <div style={{ display: 'flex', gap: '4px', flexWrap: 'nowrap', alignItems: 'center' }}>
                  <span className="outline-key-span">id: {user.id}</span>
                  <span className="outline-key-span">{user.username}</span>

                  <span className="outline-key-span">{new Date(user.created_at).toLocaleDateString()}</span>
                  { user.username !== undefined && user.username !== null && 
                      <Button onClick={() => window.open(`https://t.me/${user.username}`, '_blank')} text={'🪡 Telegram'} />}
              </div>
              {webUser !== null && <>
              
                  <Heading icon={'🕸️'} text={"Web Account"} />
                  <div style={{ display: 'flex', gap: '4px', flexWrap: 'nowrap', alignItems: 'center' }}>
                  <span className="outline-key-span">{webUser.email}</span>
                      
                  <Button onClick={editEmail} text={'📦 Отредактировать почту'} />
                      <Button onClick={handleWebPasswordRecovery} text={'🤐 Сменить пароль'} />

                      {recoveredPassword !== null && <CopyButton copyValue={recoveredPassword} text={'✏️ Копировать новый пароль'} activeText = "✅ Скопировано"/>}
              </div>
              </>}

              <Heading icon={'🔑'} text={"Ключи пользователя - Outline"} />
              <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                  {userKeys && userKeys.map((key) => {
                      return <KeyRow type='outline' keyInformation={key}/>
                  })}
                  </div>
              <Heading icon={'🔑'} text={"Ключи пользователя - Vless"} />
              <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                  {userVlessKeys && userVlessKeys.map((key) => {
                      return <KeyRow type='vless' keyInformation={key}/>
                  })}
                </div>
              <Heading icon={'🛒'} text={"Заказы пользователя"} />
              {userOrders.map((order) => {
                  return <OrderRow order={order}></OrderRow>
              })}
          </div>
          </div></>
  )
}