import { useEffect, useState } from 'react';
import Loader from './helpers/Loader';
import axios from 'axios';
export default function ServerOverlayVless({ id, close, getList }) {
	const [isLoading, setLoading] = useState(true);
	const [comment, setComment] = useState('');

	const [serverData, setServerData] = useState({});
	const [userLimit, setUserLimit] = useState(null);
	const API_URL = 'https://dusk.masliukov.com/v2-api/vless/';
	const [isSaving, setSaving] = useState(false);
	const [wantDeleteServer, setWantDeleteServer] = useState(false);
	const [isDeletingServer, setDeletingServer] = useState(false);
	const [serverStatus, setServerStatus] = useState(false);
	const [isTogglingServer, setTogglingServer] = useState(false);

	const loadServerData = async () => {
		let URL = 'getServer';
		const data = {
			id: id,
		};
		console.log(id);
		const request = await axios.post(API_URL + URL, data);
		console.log({ data: request.data });
		setComment(request.data.server.comment);
		setServerStatus(request.data.server.enabled);
		setUserLimit(request.data.server.user_limit !== 0 ? request.data.server.user_limit : '');
		setServerData(request.data.server);
		setLoading(false);
	};

	useEffect(() => {
		loadServerData();
	}, []);

	const handleClose = (event) => {
		const { id } = event.target;
		if (id === 'overlay') {
			close();
		}
	};

	const toggleKey = async () => {
		setTogglingServer(true);
		let URL = '';

		if (serverStatus) {
			URL = API_URL + 'disableKey';
		} else {
			URL = API_URL + 'enableKey';
		}

		const data = {
			id: id,
		};

		const request = await axios.post(URL, data);

		if (request.data.ok) {
			setServerStatus(!serverStatus);
			setTogglingServer(false);
			getList();
		}
	};

	const deleteKey = async (event) => {
		setDeletingServer(true);
		let URL = 'deleteKey';
		const data = {
			id: id,
		};
		const request = await axios.post(API_URL + URL, data);
		console.log({ delete: request.data });
		if (request.data.ok) {
			close();
			getList();
		}
	};

	const saveServer = async () => {
		setSaving(true);
		const data = {
			id: id,
			comment: comment,
			user_limit: userLimit !== null && userLimit !== NaN && userLimit !== '' ? parseInt(userLimit) : 0,
		};
		console.log(data);
		let URL = 'saveServer';

		const request = await axios.post(API_URL + URL, data);
		if (request.data.ok) {
			setSaving(false);
			getList();
		}
	};

	return (
		<div className='overlay' id='overlay' onClick={handleClose}>
			<div className='overlay-content overlay-content-server'>
				{isLoading ? (
					<div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '300px' }}>
						<Loader />
					</div>
				) : (
					<>
						<div className='server-label'>
							<span>id: </span>
							{serverData.id}
						</div>

						<div className='server-label'>
							<span>url/key_url: </span>
							{serverData.url} / {serverData.key_url}
						</div>
						<div className='server-label'>
							<span>login/password: </span>
							{serverData.login} / {serverData.password}
						</div>
						{comment.length !== 0 && <div>Comment / Name</div>}
						<input type='text' className='t-input' value={comment} placeholder='Comment' onChange={(e) => setComment(e.target.value)} />
						{comment.length !== 0 && <div>User limit</div>}
						<input
							type='number'
							className='t-input'
							value={userLimit}
							placeholder='User Limit'
							onChange={(e) => setUserLimit(e.target.value)}
						/>

						<button className='t-button' onClick={toggleKey}>
							{isTogglingServer ? <Loader /> : serverStatus ? 'Выключить сервер' : 'Включить сервер'}
						</button>
						<button className='t-button t-primary' onClick={saveServer}>
							{isSaving ? <Loader /> : 'Сохранить'}
						</button>
						<div>
							<input
								type='checkbox'
								id='trial-select'
								checked={wantDeleteServer}
								onChange={() => setWantDeleteServer(!wantDeleteServer)}
								name='trial-select'
								label='Хочу удалить сервер'
							></input>
							<label className='t-checkbox-label'>Хочу удалить сервер</label>
						</div>

						{wantDeleteServer && (
							<button onClick={deleteKey} className='t-button t-danger'>
								{isDeletingServer ? <Loader /> : 'Удалить сервер'}
							</button>
						)}
					</>
				)}
			</div>
		</div>
	);
}
