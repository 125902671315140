import React, { PureComponent } from 'react';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useState, useEffect } from 'react';
import axios from 'axios';
import './cards.css';

import Chart from '../components/Chart';
import CardTemplate from '../components/CardTemplate';
import TarrifCard from '../components/TarrifCard';
import MonthlyRound from '../components/MonthlyRound';
import ProjectLTV from '../components/ProjectLTV';
import ProjectAverageBill from '../components/ProjectAverageBill';
export default function Statistics() {
	const URL = 'https://dusk.masliukov.com/v2-api/statistics/graph';

	const CustomTooltip = ({ active, payload, label }) => {
		if (active && payload && payload.length) {
			return (
				<div className='custom-tooltip'>
					<div className='custom-tooltip-date'>{`${new Date(label).toLocaleDateString()}`}</div>
					<div>
						{payload.map((pld) => (
							<div style={{ display: 'flex' }}>
								<div> {pld.value} Users</div>
							</div>
						))}
					</div>
				</div>
			);
		}

		return null;
	};

	const [data, setData] = useState([]);
	const [data2, setData2] = useState([]);

	//const [allCustomers, setAllcustomers] = useState(0);

	const [all, setAll] = useState(0);
	const [today, setToday] = useState(0);
	const [todayUniq, setTodayUniq] = useState(0);
	const [yesterday, setYesterday] = useState(0);
	const [yesterdayUniq, setYesterdayUniq] = useState(0);
	const [week, setWeek] = useState(0);
	const [weekUniq, setWeekUniq] = useState(0);
	const [month, setMonth] = useState(0);
	const [monthUniq, setMonthUniq] = useState(0);
	const [total, setTotal] = useState(0);
	const [monthMinus, setMonthMinus] = useState(0);
	const [month2Minus, setMonth2Minus] = useState(0);

	const [fall, setFAll] = useState(0);
	const [ftoday, setFToday] = useState(0);
	const [ftodayUniq, setFTodayUniq] = useState(0);
	const [fYesterday, setFYesterday] = useState(0);
	const [fYesterdayUniq, setFYesterdayUniq] = useState(0);
	const [fweek, setFWeek] = useState(0);
	const [fweekUniq, setFWeekUniq] = useState(0);
	const [fmonth, setFMonth] = useState(0);
	const [fmonthUniq, setFMonthUniq] = useState(0);
	const [fmonthMinus, setFMonthMinus] = useState(0);
	const [fmonth2Minus, setFMonth2Minus] = useState(0);

	const [yearall, setyearAll] = useState(0);
	const [yeartoday, setyearToday] = useState(0);
	const [yeartodayUniq, setyearTodayUniq] = useState(0);
	const [yearYesterday, setyearYesterday] = useState(0);
	const [yearYesterdayUniq, setyearYesterdayUniq] = useState(0);
	const [yearweek, setyearWeek] = useState(0);
	const [yearweekUniq, setyearWeekUniq] = useState(0);
	const [yearmonth, setyearMonth] = useState(0);
	const [yearmonthUniq, setyearMonthUniq] = useState(0);
	const [yearmonthMinus, setyearMonthMinus] = useState(0);
	const [yearmonth2Minus, setyearMonth2Minus] = useState(0);

	const [sall, setSAll] = useState(0);
	const [stoday, setSToday] = useState(0);
	const [stodayUniq, setSTodayUniq] = useState(0);
	const [sYesterday, setSYesterday] = useState(0);
	const [sYesterdayUniq, setSYesterdayUniq] = useState(0);
	const [sWeek, setSWeek] = useState(0);
	const [sWeekUniq, setSWeekUniq] = useState(0);
	const [sMonth, setSMonth] = useState(0);
	const [sMonthUniq, setSMonthUniq] = useState(0);
	const [sMonthMinus, setSMonthMinus] = useState(0);
	const [sMonth2Minus, setSMonth2Minus] = useState(0);

	const [activeCustomers, setActiveCustomers] = useState(0);
	const [prevActiveCustomers, setPrevActiveCustomers] = useState(0);

	const [graph1, setGraph1] = useState(null);
	const [graph2, setGraph2] = useState(null);

	const [ltvArray, setLtvArray] = useState([]);

	const [uniqueGraph, setUniqueGraph] = useState([]);

	useEffect(() => {
		loadStats();
	}, []);

	const calculateGraph = (graph) => {
		let c = 0;
		let p = 0;
		for (let i = 0; i < graph.length; i++) {
			p = p + parseInt(graph[i].total_revenue);
			c = c + parseInt(graph[i].order_count);
		}
		return { count: c, price: p };
	};

	const loadStats = async () => {
		const req = await axios.post(URL);

		setData(req.data.graph);
		setData2(req.data.ppp);

		const g1 = calculateGraph(req.data.graph);
		const g2 = calculateGraph(req.data.ppp);

		setGraph1(g1);
		setGraph2(g2);

		const { sto, five, six, total, familyYear, totalUniq } = req.data;

		setUniqueGraph(req.data.uniqueGraph);

		setPrevActiveCustomers(req.data.uniqueGraph[req.data.uniqueGraph.length - 2].amount);

		setTotal(total);
		//setAllcustomers(totalUniq);

		setActiveCustomers(totalUniq);

		// console.log(req.data);

		try {
			setAll(sto.all[0].count);
			setToday(sto.today[0].count);
			setTodayUniq(sto.todayUniq[0].count);
			setYesterday(sto.yesterday[0].count);
			setYesterdayUniq(sto.yesterdayUniq[0].count);
			setWeek(sto.week[0].count);
			setWeekUniq(sto.weekUniq[0].count);
			setMonth(sto.month[0].count);
			setMonthUniq(sto.monthUniq[0].count);
			setMonthMinus(sto.monthMinus[0].count);
			setMonth2Minus(sto.month2Minus[0].count);

			setFAll(five.all[0].count);
			setFToday(five.today[0].count);
			setFTodayUniq(five.todayUniq[0].count);
			setFYesterday(five.yesterday[0].count);
			setFYesterdayUniq(five.yesterdayUniq[0].count);
			setFWeek(five.week[0].count);
			setFWeekUniq(five.weekUniq[0].count);
			setFMonth(five.month[0].count);
			setFMonthUniq(five.monthUniq[0].count);
			setFMonthMinus(five.monthMinus[0].count);
			setFMonth2Minus(five.month2Minus[0].count);

			setyearAll(familyYear.all[0].count);
			setyearToday(familyYear.today[0].count);
			setyearTodayUniq(familyYear.todayUniq[0].count);
			setyearYesterday(familyYear.yesterday[0].count);
			setyearYesterdayUniq(familyYear.yesterdayUniq[0].count);
			setyearWeek(familyYear.week[0].count);
			setyearWeekUniq(familyYear.weekUniq[0].count);
			setyearMonth(familyYear.month[0].count);
			setyearMonthUniq(familyYear.monthUniq[0].count);
			setyearMonthMinus(familyYear.monthMinus[0].count);
			setyearMonth2Minus(familyYear.month2Minus[0].count);

			setSAll(six.all[0].count);
			setSToday(six.today[0].count);
			setSTodayUniq(six.todayUniq[0].count);
			setSYesterday(six.yesterday[0].count);
			setSYesterdayUniq(six.yesterdayUniq[0].count);
			setSWeek(six.week[0].count);
			setSWeekUniq(six.weekUniq[0].count);
			setSMonth(six.month[0].count);
			setSMonthUniq(six.monthUniq[0].count);
			setSMonthMinus(six.monthMinus[0].count);
			setSMonth2Minus(six.month2Minus[0].count);
		} catch (e) {
			console.log(e);
		}
		// setAll(sto.all[0].count);
	};

	// тип это прайс

	// Всего
	// Всего по типу / уникальные (новые)

	// Проданные за сегодня по типу / уникальные (новые)
	// Проданные за сегодня всего

	// Проданные за неделю по типу
	// Проданные за неделю всего / уникальные (новые)

	// Проданные за месяц по типу
	// Проданные за месяц всего / уникальные (новые)

	// Проданные за предпоследний месяц по типу
	// Проданные за предпоследний месяц всего / уникальные (новые)

	return (
		<div className='content-block t-container'>
			<div className='t-content'>
				<h1>Analytics</h1>
				<div className='cards-container'>
					<CardTemplate
						heading={'Users'}
						subheading={'Current users / Yesterday '}
						prevValue={prevActiveCustomers}
						value={activeCustomers}
					></CardTemplate>
					<CardTemplate heading={'Subscriptions'} subheading={'Current subscriptions'} value={total}></CardTemplate>
					<CardTemplate
						heading={'Revenue'}
						subheading={'This month / Previous'}
						type='rubles'
						value={graph1 !== null ? graph1.price : 0}
						prevValue={graph2 !== null ? graph2.price : 0}
					></CardTemplate>
					<CardTemplate
						heading={'Sales'}
						subheading={'This month / Previous'}
						value={graph1 !== null ? graph1.count : 0}
						prevValue={graph2 !== null ? graph2.count : 0}
					></CardTemplate>
				</div>
				<Chart graph={graph1} days='30' days2='30-60' data={data} graph2={graph2} data2={data2} />
				<MonthlyRound />
				<h1>По тарифам</h1>
				<div className='tarrifs-container'>
					<TarrifCard
						tarrifName={'Базовый'}
						rows={[
							{ name: 'Активные', value: all },
							{ name: 'Сегодня / Новых', value: today + ' / ' + todayUniq },
							{ name: 'Вчера / Новых', value: yesterday + ' / ' + yesterdayUniq },
							{ name: 'За неделю / Новых', value: week + ' / ' + weekUniq },
							{ name: 'За месяц', value: month + ' / ' + monthUniq },
							{ name: 'Минус за месяц / Два', value: monthMinus + ' / ' + month2Minus },
						]}
					/>
					<TarrifCard
						tarrifName={'Семейный'}
						rows={[
							{ name: 'Активные', value: fall },
							{ name: 'Сегодня / Новых', value: ftoday + ' / ' + ftodayUniq },
							{ name: 'Вчера / Новых', value: fYesterday + ' / ' + fYesterdayUniq },
							{ name: 'За неделю / Новых', value: fweek + ' / ' + fweekUniq },
							{ name: 'За месяц', value: fmonth + ' / ' + fmonthUniq },
							{ name: 'Минус за месяц / Два', value: fmonthMinus + ' / ' + fmonth2Minus },
						]}
					/>
					<TarrifCard
						tarrifName={'Семейный Годовой'}
						rows={[
							{ name: 'Активные', value: yearall },
							{ name: 'Сегодня / Новых', value: yeartoday + ' / ' + yeartodayUniq },
							{ name: 'Вчера / Новых', value: yearYesterday + ' / ' + yearYesterdayUniq },
							{ name: 'За неделю / Новых', value: yearweek + ' / ' + yearweekUniq },
							{ name: 'За месяц', value: yearmonth + ' / ' + yearmonthUniq },
							{ name: 'Минус за месяц / Два', value: yearmonthMinus + ' / ' + yearmonth2Minus },
						]}
					/>
					<TarrifCard
						tarrifName={'Премиум'}
						rows={[
							{ name: 'Активные', value: sall },
							{ name: 'Сегодня / Новых', value: stoday + ' / ' + stodayUniq },
							{ name: 'Вчера / Новых', value: sYesterday + ' / ' + sYesterdayUniq },
							{ name: 'За неделю / Новых', value: sWeek + ' / ' + sWeekUniq },
							{ name: 'За месяц', value: sMonth + ' / ' + sMonthUniq },
							{ name: 'Минус за месяц / Два', value: sMonthMinus + ' / ' + sMonth2Minus },
						]}
					/>
				</div>

				<h1>Active Paying Users</h1>
				<div style={{ height: '500px' }}>
					<ResponsiveContainer className='custom-container'>
						<LineChart margin={{ top: 32, left: 32, right: 32, bottom: 32 }} width={500} height={500} data={uniqueGraph}>
							<Line dot={false} name='Users: ' type='monotone' dataKey='amount' stroke='#FFF' />
							<XAxis hide={true} name='OrderDate: ' dataKey='date' />
							<Tooltip content={<CustomTooltip />} />
							<YAxis hide={true} domain={[0, 20000]} />
						</LineChart>
					</ResponsiveContainer>
				</div>
				{/* <h1>LTV and Unique Orders</h1> */}
				{/* <ProjectLTV uniqueGraph={uniqueGraph} /> */}

				<h1>Average Order Value</h1>
				<ProjectAverageBill />
			</div>
		</div>
	);
}
