import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Bar, BarChart, CartesianGrid, Legend, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

export default function MonthlyRound() {
	const million = 1000000;
	const [maxLevel, setMaxLevel] = useState(3.5 * million);
	const [data, setData] = useState([]);

	const loadComponent = async () => {
		const request = await axios.post('https://dusk.masliukov.com/v2-api/statistics/year');
		const statsByMonth = request.data.result;
		let maxValue = 0;
		for (let i = 0; i < statsByMonth.length; i++) {
			const month = statsByMonth[i];
			const monthValueInt = parseInt(month.sum);
			if (maxValue < monthValueInt) {
				maxValue = monthValueInt;
			}
		}
		const nextGoal = maxValue * 1.1;
		setMaxLevel(nextGoal);
		setData(request.data.result);
	};

	useEffect(() => {
		loadComponent();
	}, []);

	const CustomTooltip = ({ active, payload, label }) => {
		if (active && payload && payload.length) {
			return (
				<div className='custom-tooltip'>
					<div className='custom-tooltip-date'>{`${label}`}</div>
					<div>
						{payload.map((pld) => (
							<div className='custom-tooltip-content'>
								<div>{pld.dataKey === 'sum' ? 'Прибыль' : pld.dataKey === 'count' ? 'Покупки' : ''}</div>
								<div>
									{pld.value}
									{pld.dataKey === 'sum' && '₽'}{' '}
								</div>
							</div>
						))}
					</div>
				</div>
			);
		}

		return null;
	};

	return (
		<div className='charts-grid'>
			<ResponsiveContainer className='custom-container'>
				<BarChart margin={{ top: 32, left: 32, right: 32, bottom: 32 }} width={500} height={500} data={data}>
					<YAxis hide={true} yAxisId='left-axis' domain={[0, maxLevel]} orientation='left' dataKey='sum' />
					<Bar yAxisId='left-axis' name='Revenue: ' dataKey='sum' fill='#909090' />
					<XAxis hide={true} name='OrderDate: ' dataKey='month' />
					<Tooltip content={<CustomTooltip />} />
				</BarChart>
			</ResponsiveContainer>
		</div>
	);
}
