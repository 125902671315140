import { useState, useEffect } from 'react';
import axios from 'axios';
import Loader from './helpers/Loader';
export default function CampaignOverlay({ loadDeeplinks, close }) {
  const API_URL = 'https://dusk.masliukov.com/v2-api/promo/';

  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [discount, setDis] = useState('');
  const [trialDays, setTrialDays] = useState('');
  const [isTrialShow, setTrialShown] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (!isTrialShow) {
      setTrialDays('');
    }
  }, [isTrialShow]);

  const createDeeplink = async () => {
    if (code.length < 4) {
      return alert('Code cannot be shorter than 4 char');
    }
    if (code.length > 20) {
      return alert('Code cannot be longer than 20 char');
    }
    if (name.length === 0) {
      return alert('Name cannot be none');
    }
    if (isLoading) {
      return;
    }

    setLoading(true);
    const data = {
      name: name,
      code: code,
      trial_days: trialDays === '' ? 0 : trialDays,
      discount: discount === '' ? 0 : discount,
    };

    const req = await axios.post(API_URL + 'createPromo', data);
    if (req.data.ok === true) {
      loadDeeplinks();
      setLoading(false);
      close();
    }
  };

  const handleClose = (event) => {
    const { id } = event.target;
    if (id === 'overlay') {
      close();
    }
  };

  return (
    <div className="overlay" id="overlay" onClick={handleClose}>
      <div className="overlay-content">
        <div className="t-label">Создать новую кампанию</div>

        <input type="text" placeholder="Название" className="t-input" value={name} onChange={(e) => setName(e.target.value.trim())} />
        <input type="text" className="t-input" placeholder="Код" value={code} onChange={(e) => setCode(e.target.value.trim())} />
        <input type="number" className="t-input" placeholder="Скидка" value={discount} onChange={(e) => setDis(parseInt(e.target.value))} />
        <div>
          <input
            type="checkbox"
            id="trial-select"
            checked={isTrialShow}
            onClick={() => setTrialShown(!isTrialShow)}
            name="trial-select"
            label="Пробный период для новых пользователей"
          ></input>
          <label className="t-checkbox-label">Пробный период для новых пользователей</label>
        </div>

        {isTrialShow && (
          <input
            type="text"
            className="t-input"
            placeholder="Триальные дни"
            value={trialDays}
            onChange={(e) => setTrialDays(e.target.value.trim())}
          />
        )}
        <button onClick={createDeeplink} className="t-button t-wide t-primary">
          {isLoading ? <Loader /> : <>Создать</>}
        </button>
      </div>
    </div>
  );
}
