import axios from 'axios';
import { useState, useEffect } from 'react';

export default function Migration() {
	const [limit, setLimit] = useState(0);

	const [queryFrom, setQueryFrom] = useState('');
	const [queryTo, setQueryTo] = useState('');

	const [showConfirm, setConfirm] = useState(false);
	const [timeLimit, setTimeLimit] = useState(99);
	const [isLoading, setLoading] = useState(false);
	const [allServers, setAllServers] = useState([]);

	const [selectedServerFrom, setSelectedServerFrom] = useState('');
	const [selectedServerTo, setSelectedServerTo] = useState('');

	const [availableFrom, setAvailableFrom] = useState([]);

	const [availableTo, setAvailableTo] = useState([]);

	const getServers = async () => {
		const request = await axios.post('https://dusk.masliukov.com/v4/outline/getList');
		const servers = request.data.keys;
		console.log(servers);
		setAllServers(servers);
	};

	useEffect(() => {
		getServers();
	}, []);

	const migrateUsers = async (event) => {
		event.preventDefault();

		const data = {
			server_api_from: selectedServerFrom,
			server_api_to: selectedServerTo,
			limit: limit,
		};

		console.log({ data });
		if (selectedServerFrom === '' || selectedServerTo === '') {
			return;
		}

		console.log({ data });

		setLoading(true);

		const request = await axios.post('https://dusk.masliukov.com/v4/migrateGroup', data);
		console.log({ request: request.data });
		setLoading(false);

		if (request.data.ok) {
			alert('Success');
		}
	};

	useEffect(() => {
		if (allServers.length === 0) {
			return;
		}
		console.log('UPDATE');
		const filters = allServers.filter((server) => server.api.includes(queryTo));
		setAvailableTo(filters);
		if (filters[0]) {
			setSelectedServerTo(filters[0].api);
		}
	}, [queryTo]);

	useEffect(() => {
		if (allServers.length === 0) {
			return;
		}
		console.log('UPDATE');
		const filters = allServers.filter((server) => server.api.includes(queryFrom));
		setAvailableFrom(filters);
		if (filters[0]) {
			setSelectedServerFrom(filters[0].api);
		}
	}, [queryFrom]);

	return (
		<div className='content-block t-container'>
			<div className='t-content'>
				<div className='page-header'>
					<h1>Migration</h1>
				</div>
			</div>
			<form onSubmit={migrateUsers} className='t-content'>
				<div className='migration-c'>
					<div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
						Current Server
						<input
							type='text'
							className='t-input'
							placeholder='Filter IP Address (OLD)'
							value={queryFrom}
							onChange={(e) => setQueryFrom(e.target.value)}
						></input>
						<select
							value={selectedServerFrom}
							onChange={(e) => {
								console.log({ e: e.target.value });
								setSelectedServerFrom(e.target.value);
							}}
							className='t-input'
						>
							{availableFrom.map((server) => {
								return (
									<option value={server.api}>
										<b>{server.comment}</b> {' | ' + server.api}
									</option>
								);
							})}
						</select>
					</div>
					<div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
						New Server
						<input
							type='text'
							className='t-input'
							placeholder='Filter IP Address (NEW)'
							value={queryTo}
							onChange={(e) => setQueryTo(e.target.value)}
						></input>
						<select
							value={selectedServerTo}
							onChange={(e) => {
								console.log({ e: e.target.value });
								setSelectedServerTo(e.target.value);
							}}
							className='t-input'
						>
							{availableTo.map((server) => {
								return (
									<option value={server.api}>
										<b>{server.comment}</b> {' | ' + server.api}
									</option>
								);
							})}
						</select>
					</div>
					<div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
						Migration User Limit
						<input
							type='number'
							className='t-input'
							value={limit}
							onChange={(e) => setLimit(e.target.value)}
							placeholder='Migration User Limit'
						/>
					</div>
				</div>

				<button type='submit' disabled={isLoading} className='t-button'>
					{isLoading ? <>Migrating...</> : <>Start Migration</>}
				</button>
			</form>
		</div>
	);
}
