import React, { useState, useEffect } from 'react';

import axios from 'axios';
import ServerRow from '../components/ServerRow';
import ServerCreationOverlayVless from '../components/ServerCreationOverlayVless';
import ServerOverlayVless from '../components/ServerOverlayVless';

export default function SettingsVless() {
	const API_URL = 'https://dusk.masliukov.com/v2-api/vless/';
	const [keys, setKeys] = useState([]);
	const [totalKeys, setTotalKeys] = useState(0);
	const [selectedServer, setSelectedServer] = useState(null);

	const [isServerOverlay, setServerOverlay] = useState(false);
	const [isCreationOverlay, setCreationOverlay] = useState(false);

	useEffect(() => {
		getList();
	}, []);

	const getLocalKeys = async () => {
		const CALL_URL = API_URL + 'getList';
		const request = await axios.post(CALL_URL);
		setKeys(request.data.keys);
	};

	const getList = async () => {
		const CALL_URL = API_URL + 'getList';
		const request = await axios.post(CALL_URL);
		const _k = request.data.keys;
		console.log({ _k });
		setKeys(request.data.keys);
		countLocalKeys(_k);
	};

	const countLocalKeys = (_k) => {
		let _t = 0;
		let counted = [];
		for (let i = 0; i < _k.length; i++) {
			const key = _k[i];
			if (!counted.includes(key.api)) {
				counted.push(key.api);
				_t = _t + parseInt(key.amountofusers);
			}
		}
		setTotalKeys(_t);
	};

	const openServer = (id) => {
		console.log({ openServer: id });
		setSelectedServer(id);
		setServerOverlay(true);
	};

	const closeServer = () => {
		setSelectedServer(null);
		setServerOverlay(false);
	};

	return (
		<>
			{isCreationOverlay && <ServerCreationOverlayVless getList={getList} close={() => setCreationOverlay(false)} />}
			{isServerOverlay && <ServerOverlayVless getList={getLocalKeys} close={closeServer} id={selectedServer} />}
			<div className='content-block t-container'>
				<div className='t-content'>
					<div className='page-header'>
						<h1>
							VLESS / <span>{totalKeys}</span>
						</h1>
						<div className='header-buttons-group'>
							<button className='t-button' onClick={(e) => setCreationOverlay(true)}>
								Создать
							</button>
						</div>
					</div>
				</div>
				<div className='row-qiwi labels'>
					<div className='qiwi-column'>Status</div>
					<div className='qiwi-column'>Plan</div>
					<div className='qiwi-column'>Id</div>
					<div className='qiwi-column'>Comment</div>
					<div className='qiwi-column'>Key</div>
					<div className='qiwi-column'>Users</div>
				</div>

				{keys.map((key) => {
					return <ServerRow type='vless' openServer={openServer} server={key} />;
				})}
			</div>
		</>
	);
}
