import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CampaignRow from '../components/CampaignRow';
import CampaignOverlay from '../components/CampaignOverlay';
import CampaignSettingsOverlay from '../components/CampaignSettingsOverlay';

export default function CreatePromo() {
  // plan, how long,

  const API_URL = 'https://dusk.masliukov.com/v2-api/promo/';
  const [deeplinks, setDeeplinks] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [isCampaignShowing, setCampaignShowing] = useState(false);

  const [isOverlayShown, setOverlayShown] = useState(false);

  useEffect(() => {
    loadDeeplinks();
  }, []);

  const loadDeeplinks = async () => {
    const request = await axios.post(API_URL + 'getList');
    console.log(request.data.promo);
    setDeeplinks(request.data.promo);
  };

  const openCampaign = (id) => {
    setCampaignShowing(true);
    setSelectedCampaign(id);
  };

  return (
    <>
      {isOverlayShown && <CampaignOverlay loadDeeplinks={loadDeeplinks} close={() => setOverlayShown(false)} />}
      {isCampaignShowing && <CampaignSettingsOverlay id={selectedCampaign} loadDeeplinks={loadDeeplinks} close={() => setCampaignShowing(false)} />}
      <div className="content-block t-container">
        <div className="t-content">
          <div className="page-header">
            <h1>Campaigns</h1>
            <div className="header-buttons-group">
              <button className="t-button" onClick={(e) => setOverlayShown(true)}>
                Создать
              </button>
            </div>
          </div>
          <input type="text" value={searchString} onChange={(e) => setSearchString(e.target.value)} className="t-input" placeholder="Search"></input>
        </div>
        <div className="row-promo">
          <div></div>
          <div className="deeplink-column t-left">Info</div>
          <div className="deeplink-column">Leeds / Proceeds / CR</div>
          <div className="deeplink-column">Revenue</div>
          <div className="deeplink-column">ARPU / ARPPU</div>
          <div className="deeplink-column">free days</div>
          <div className="deeplink-column">Discount</div>
        </div>
        {deeplinks.map((promo) => {
          if (searchString.trim().length > 0) {
            if (promo.promo_id.startsWith(searchString.trim())) {
              return (
                <CampaignRow
                  openCampaign={openCampaign}
                  promo_name={promo.promo_name}
                  is_active={promo.is_active}
                  promo_id={promo.promo_id}
                  expire_date={promo.expire_date}
                  new_users={promo.new_users}
                  bought_users={promo.bought_users}
                  profit={promo.profit}
                  free_days={promo.trial_days}
                  created_at={promo.created_at}
                  discount={promo.discount}
                />
              );
            }
            if (promo.promo_name.startsWith(searchString.trim())) {
              return (
                <CampaignRow
                  openCampaign={openCampaign}
                  is_active={promo.is_active}
                  promo_name={promo.promo_name}
                  expire_date={promo.expire_date}
                  promo_id={promo.promo_id}
                  new_users={promo.new_users}
                  bought_users={promo.bought_users}
                  profit={promo.profit}
                  free_days={promo.trial_days}
                  created_at={promo.created_at}
                  discount={promo.discount}
                />
              );
            }
            return <></>;
          } else {
            return (
              <CampaignRow
                openCampaign={openCampaign}
                is_active={promo.is_active}
                promo_name={promo.promo_name}
                promo_id={promo.promo_id}
                new_users={promo.new_users}
                bought_users={promo.bought_users}
                expire_date={promo.expire_date}
                profit={promo.profit}
                free_days={promo.trial_days}
                created_at={promo.created_at}
                discount={promo.discount}
              />
            );
          }
        })}
      </div>
    </>
  );
}
