import './tarrif.css';
import React from 'react';
export default function TarrifCard({ tarrifName, rows }) {
  return (
    <div className="tarrif-card">
      <div className="tarrif-name">{tarrifName}</div>
      {rows.map((row, index) => {
        return (
          <div className="tarrif-row">
            <div className="tarrif-label">{row.name}</div>
            <div className={rows.length - 1 !== index ? 'tarrif-value' : 'tarrif-value-red'}>{row.value}</div>
          </div>
        );
      })}
    </div>
  );
}
