import { useEffect, useState } from 'react';
import axios from 'axios';
import Loader from './helpers/Loader';

export default function DeeplinkOverlay({ close }) {
  const API_URL = 'https://dusk.masliukov.com/v2-api/deeplink/';

  const [months, setMonths] = useState(null);
  const [newPlan, setPlan] = useState(0);
  const [timeType, setTimeType] = useState('months');
  const [result, setResult] = useState(null);
  const [isCopied, setCopied] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const copyToClipboard = (event) => {
    event.preventDefault();
    let key = event.currentTarget.dataset.key;
    navigator.clipboard.writeText('https://t.me/simple_vpn_bot?start=' + key);
    setCopied(true);
  };

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setCopied(false);
      }, 500);
    }
  }, [isCopied]);

  const createDeeplink = async () => {
    setLoading(true);
    const data = {
      duration: months === null ? 1 : months,
      plan: newPlan,
      timeType: timeType,
    };
    const req = await axios.post(API_URL + 'create', data);
    if (req.data.ok === true) {
      const { deeplink } = req.data;
      setResult(deeplink);
    }
    setLoading(false);
  };

  const handleClose = (event) => {
    const { id } = event.target;
    if (id === 'overlay') {
      close();
    }
  };

  return (
    <div className="overlay" id="overlay" onClick={handleClose}>
      <div className="overlay-content overlay-content-server">
        <div className="t-label">{result === null ? 'Создать' : 'Ваш'} промокод</div>
        {result !== null ? (
          <>
            <div>{result}</div>
            <button onClick={copyToClipboard} data-key={result} className="t-button t-wide t-primary">
              {isCopied ? <>Скопировано</> : <>Копировать</>}
            </button>
          </>
        ) : (
          <>
            <div className="overlay-row">
              <input
                className="t-input"
                placeholder="Количество месяцев / дней"
                value={months}
                min="1"
                onChange={(event) => setMonths(parseInt(event.target.value))}
                type="number"
              ></input>
              <select className="t-input" value={timeType} onChange={(e) => setTimeType(e.target.value)}>
                <option value="months">Месяцы</option>
                <option value="days">Дни</option>
              </select>
            </div>
            <select className="t-input" type="text" value={newPlan} onChange={(e) => setPlan(e.target.value)}>
              <option value={0}>Базовый</option>
              <option value={3}>Семейный</option>
              <option value={15}>Семейный / год</option>
              <option value={1}>Премиум</option>
              <option value={2}>Deeplink</option>
              <option value={4}>Базовый / UK 🇬🇧</option>
              <option value={5}>Базовый / Finland 🇫🇮</option>
              <option value={6}>Базовый / Germany 🇩🇪</option>
              <option value={10}>Базовый / USA 🇺🇸</option>
              <option value={12}>Базовый / Rus 🇷🇺</option>
              <option value={7}>Базовый / Yota, Tele2</option>
              <option value={8}>Семейный / UK 🇬🇧</option>
              <option value={9}>Семейный / Finland 🇫🇮</option>
              <option value={13}>Семейный / Germany 🇩🇪</option>
              <option value={14}>Семейный / USA 🇺🇸</option>
              <option value={11}>Семейный / Yota, Tele2</option>
            </select>

            <button disabled={isLoading} onClick={() => createDeeplink()} className="t-button t-primary t-wide">
              {isLoading ? <Loader /> : <>Создать</>}
            </button>
          </>
        )}
      </div>
    </div>
  );
}
