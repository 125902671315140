import React, { useState, useEffect } from 'react';
export default function DeeplinkRow({ deeplink, type = 'outline' }) {
	const [isCopied, setCopied] = useState(false);

	const copyToClipboard = (event) => {
		event.preventDefault();
		setCopied(true);
		let key = event.currentTarget.dataset.key;
		if (type === 'outline') {
			navigator.clipboard.writeText('https://t.me/simple_vpn_bot?start=' + key);
		} else {
			navigator.clipboard.writeText('https://t.me/simple_vpn_probot?start=' + key);
		}
	};
	const getPlan = (plan) => {
		let n = parseInt(plan);
		if (n === 0) return 'Базовый';
		if (n === 1) return 'Премиум';
		if (n === 2) return 'Deeplink';
		if (n === 3) return 'Семейный 6м';
		if (n === 15) return 'Семейный год';
		if (n === 4) return 'Базовый 🇬🇧';
		if (n === 5) return 'Базовый 🇫🇮';
		if (n === 6) return 'Базовый 🇩🇪';
		if (n === 10) return 'Базовый 🇺🇸';
		if (n === 7) return 'Базовый Yota';
		if (n === 11) return 'Семейный Yota';
		if (n === 8) return 'Семейный 🇬🇧';
		if (n === 9) return 'Семейный 🇫🇮';
		if (n === 13) return 'Семейный 🇩🇪';
		if (n === 14) return 'Семейный 🇺🇸';
		if (n === 12) return 'Russia';

		if (n === 20) {
			return 'Я в Китае';
		}
		if (n === 21) {
			return 'Я в Китае / 6м';
		}
	};

	useEffect(() => {
		if (isCopied) {
			setTimeout(() => {
				setCopied(false);
			}, 2000);
		}
	}, [isCopied]);
	return (
		<div className='row-deeplink'>
			<div className='deeplink-column center'>
				<div className={deeplink.is_activated ? 'status-round green' : 'status-round yellow'}></div>
			</div>
			<div className='deeplink-column'>{deeplink.chat_id === null ? '' : deeplink.chat_id + '/' + deeplink.username}</div>
			{/* <a href={'t.me/'+deeplink.username}>{deeplink.username}</a>{'/'+ 'deeplink.chat_id' === null ? deeplink.chat_id : 'Not active'}</div> */}
			<div className='deeplink-column'>{deeplink.deeplink}</div>
			<div className='deeplink-column'>
				{deeplink.months} {deeplink.time_type === 'months' && 'месяцев'} {deeplink.time_type === 'days' && 'дней'}
			</div>
			<div className='deeplink-column'>{getPlan(deeplink.plan)}</div>
			<div className='deeplink-column'>
				<button onClick={copyToClipboard} data-key={deeplink.deeplink} className='copy-round-btn'>
					{!isCopied ? (
						<>
							<svg fill='#000000' height='800px' width='800px' version='1.1' id='Capa_1' viewBox='0 0 484.457 484.457'>
								<g>
									<path
										d='M447.112,37.345C423.031,13.263,391.012,0,356.957,0c-34.057,0-66.075,13.263-90.156,37.345L166.215,137.931l21.213,21.213
		L288.013,58.558C306.428,40.143,330.913,30,356.957,30c26.043,0,50.527,10.143,68.942,28.558s28.558,42.899,28.558,68.942
		c0,26.044-10.143,50.528-28.558,68.943L325.313,297.029l21.213,21.213l100.586-100.586c24.082-24.081,37.345-56.1,37.345-90.156
		S471.194,61.426,447.112,37.345z'
									/>
									<path
										d='M196.443,425.899c-18.415,18.415-42.899,28.558-68.942,28.558s-50.527-10.143-68.943-28.558
		C40.142,407.484,30,383,30,356.957c0-26.044,10.142-50.528,28.557-68.943l100.586-100.586l-21.213-21.213L37.344,266.801
		C13.263,290.882,0,322.9,0,356.957c0,34.056,13.263,66.074,37.344,90.155c24.082,24.082,56.1,37.345,90.156,37.345
		s66.075-13.263,90.156-37.345l100.586-100.586l-21.213-21.213L196.443,425.899z'
									/>
									<path d='M321.688,141.552l21.213,21.213L162.768,342.898l-21.213-21.213L321.688,141.552z' />
								</g>
							</svg>
						</>
					) : (
						<>
							<svg width='800px' height='800px' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
								<title />

								<g id='Complete'>
									<g id='tick'>
										<polyline
											fill='none'
											points='3.7 14.3 9.6 19 20.3 5'
											stroke='#000000'
											stroke-linecap='round'
											stroke-linejoin='round'
											stroke-width='2'
										/>
									</g>
								</g>
							</svg>
						</>
					)}
				</button>
			</div>
		</div>
	);
}
