import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { LineChart, Line, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts';

export default function ProjectAverageBill({ uniqueGraph }) {
	const [data, setData] = useState([]);

	const loadComponent = async () => {
		try {
			const request = await axios.post('https://dusk.masliukov.com/v2-api/statistics/avg_bill');
			setData(request.data.avg);
			const data = request.data.avg;
			for (let i = 0; i < data.length; i++) {
				data[i].average_bill = parseInt(data[i].average_bill);
			}
			setData(data);
		} catch (Avgerror) {
			console.error({ Avgerror });
		}
	};

	useEffect(() => {
		loadComponent();
	}, []);

	const CustomTooltip = ({ active, payload, label }) => {
		if (active && payload && payload.length) {
			return (
				<div className='custom-tooltip'>
					<div className='custom-tooltip-date'>{payload[0].payload.order_week.split('T')[0]}</div>
					<div>
						{payload.map((pld) => (
							<div style={{ display: 'flex' }}>
								<div> {pld.dataKey === 'user_ltv' ? pld.value + '₽' : pld.value + '₽ '}</div>
							</div>
						))}
					</div>
				</div>
			);
		}

		return null;
	};

	return (
		<div style={{ height: '500px' }}>
			<ResponsiveContainer className='custom-container'>
				<LineChart margin={{ top: 32, left: 32, right: 32, bottom: 32 }} width={500} height={500} data={data}>
					<Line dot={false} yAxisId='right-axis' name='users: ' type='monotone' dataKey='average_bill' stroke='#FFF' />
					<XAxis hide={true} name='OrderDate: ' dataKey='order_day' />
					<YAxis hide={true} yAxisId='right-axis' domain={[0, 300]} orientation='right' dataKey='average_bill' />
					<Tooltip content={<CustomTooltip />} />
				</LineChart>
			</ResponsiveContainer>
		</div>
	);
}
