import { useState, useEffect } from 'react';
import Loader from './helpers/Loader';
import axios from 'axios';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker from '@hassanmojab/react-modern-calendar-datepicker';

export default function CampaignSettingsOverlay({ id, close }) {
  const [isCopied, setCopied] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const [promoName, setPromoName] = useState('');
  const [promoDiscount, setPromoDiscount] = useState('');
  const [promoTrialDays, setPromoTrialDays] = useState('');
  const [promoAcquisition, setPromoAcquisition] = useState('');
  const [isOneTime, setOneTime] = useState(false);

  const [campaignObject, setCampaignObject] = useState({});

  const [expireDate, setExpireDate] = useState(null);

  const API_URL = 'https://dusk.masliukov.com/v2-api/promo/';
  const loadCampaign = async () => {
    const request = await axios.post(API_URL + 'getCampaign', {
      promo_id: id,
    });
    //   {
    //     "promo_id": "eyelashes",
    //     "new_users": 0,
    //     "bought_users": 0,
    //     "profit": 0,
    //     "promo_name": "ресничные феи",
    //     "discount": 0,
    //     "created_at": "2023-07-25T15:11:55.355Z",
    //     "trial_days": 30,
    //     "acquisition": 0,
    //     "one_time": true,
    //     "expire_date": "2023-07-27T18:31:29.283Z"
    // }

    const { promo_name, discount, trial_days, acquisition, one_time, expire_date } = request.data.promo;
    console.log({ expire_date });
    setPromoName(promo_name);
    setPromoDiscount(discount);
    setPromoTrialDays(trial_days);
    setPromoAcquisition(acquisition);
    setOneTime(one_time);
    setCampaignObject(request.data.promo);
    setLoading(false);

    const expireDateDb = new Date(expire_date.split('T')[0]);
    const expireDateObject = {
      day: expireDateDb.getDate() + 1,
      month: expireDateDb.getMonth() + 1,
      year: expireDateDb.getFullYear(),
    };
    setExpireDate(expireDateObject);
  };

  const getExpireDate = () => {
    if (expireDate !== null) {
      const jsDate = new Date(expireDate.year, expireDate.month - 1, expireDate.day);
      return jsDate;
    } else {
      alert('SOMETHIGN WENT WRONG');
    }
  };

  const saveCampaign = async () => {
    setSaving(true);
    const data = {
      promo_id: id,
      promo_name: promoName.trim(),
      discount: parseInt(promoDiscount),
      trial_days: parseInt(promoTrialDays),
      acquisition: parseInt(promoAcquisition),
      one_time: isOneTime,
      expire_date: getExpireDate(),
    };

    const request = await axios.post(API_URL + 'setCampaign', data);
    console.log(request);
    setSaving(false);
  };

  useEffect(() => {
    loadCampaign();
  }, []);

  const copyToClipboard = () => {
    navigator.clipboard.writeText('https://t.me/simple_vpn_bot?start=' + id);
    setCopied(true);
  };

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setCopied(false);
      }, 4000);
    }
  }, [isCopied]);

  const handleClose = (event) => {
    if (event.target.id === 'overlay') {
      close();
    }
  };
  return (
    <div className="overlay" id="overlay" onClick={handleClose}>
      <div className="overlay-content">
        {!isLoading ? (
          <>
            <div>#{id}</div>

            <div className="t-input-label">Название кампании</div>
            <input
              value={promoName}
              onChange={(e) => setPromoName(e.target.value)}
              type="text"
              className="t-input"
              placeholder="Название кампании"
            ></input>

            <div className="t-input-label">Стоимость кампании</div>
            <input
              value={promoAcquisition}
              onChange={(e) => setPromoAcquisition(e.target.value)}
              type="number"
              className="t-input"
              placeholder="Стоимость кампании ₽"
            ></input>

            <div className="t-input-label">Скидка %</div>
            <input
              value={promoDiscount}
              onChange={(e) => setPromoDiscount(e.target.value)}
              type="number"
              max={99}
              className="t-input"
              placeholder="Скидка"
            ></input>

            <div className="t-input-label">Триальные дни</div>
            <input
              value={promoTrialDays}
              onChange={(e) => setPromoTrialDays(e.target.value)}
              type="number"
              className="t-input"
              placeholder="Триальные дни"
            ></input>

            <div className="t-input-value">Тип скидки</div>
            <div className="t-double-row">
              <button onClick={(e) => setOneTime(true)} className={!isOneTime ? 't-button ' : 't-button t-primary'}>
                Одноразовая
              </button>
              <button onClick={(e) => setOneTime(false)} className={isOneTime ? 't-button ' : 't-button t-primary'}>
                Многоразовая
              </button>
            </div>

            <div className="t-input-value">Действует до</div>
            <DatePicker value={expireDate} onChange={setExpireDate} inputPlaceholder="Select a date" shouldHighlightWeekends />

            <label className="t-label">Статистика кампании:</label>
            <div className="t-double-row">
              <div className="t-grey-value">
                <label className="t-grey-label">Conversion Rate:</label>{' '}
                {campaignObject.bought_users > 0 ? ((campaignObject.bought_users / campaignObject.new_users) * 100).toFixed(2) : 0}%
              </div>
              <div className="t-grey-value">
                <label className="t-grey-label">Leeds:</label> {campaignObject.new_users}
              </div>
            </div>
            <div className="t-double-row">
              <div className="t-grey-value">
                <label className="t-grey-label">Proceeds / Unique Proceeds:</label> {campaignObject.bought_users} / X
              </div>
              <div className="t-grey-value">
                <label className="t-grey-label">ARPU / ARPPU:</label>{' '}
                {campaignObject.profit > 0 && <>{(campaignObject.profit / campaignObject.new_users).toFixed(2)}₽</>} /{' '}
                {campaignObject.bought_users > 0 && <>{(campaignObject.profit / campaignObject.bought_users).toFixed(2)}₽</>}
              </div>
            </div>
            <div className="t-double-row">
              <div className="t-grey-value">
                <label className="t-grey-label">LTV / CAC</label>{' '}
                {promoAcquisition > 0 &&
                  campaignObject.profit + ' / ' + promoAcquisition + ' = ' + (campaignObject.profit / promoAcquisition).toFixed(2)}
              </div>
              <div className="t-grey-value">
                <label className="t-grey-label">Revenue:</label> {campaignObject.profit}₽
              </div>
            </div>
            <button className="t-button" onClick={copyToClipboard}>
              {isCopied ? <>Скопировано</> : <>Копировать ссылку</>}
            </button>
            <button className="t-button t-primary" onClick={saveCampaign}>
              {isSaving ? (
                <>
                  <Loader />
                </>
              ) : (
                <>Сохранить</>
              )}
            </button>
          </>
        ) : (
          <>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '300px' }}>
              <Loader />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
