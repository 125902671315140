import React, { Component, useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Area, AreaChart } from 'recharts';
import './chart.css';
export default function Chart(props) {
	const [maxValue, setMaxValue] = useState(135000);

	const [g1, setG1] = useState([]);
	const [g2, setG2] = useState([]);

	useEffect(() => {
		let _g1 = [];
		let _g2 = [];
		if (props.graph === null) {
			return;
		}

		let maxGraphValuePrevious = 0;
		let maxGraphValueCurrent = 0;

		for (let i = 0; i < props.data.length; i++) {
			let temp = props.data[i];
			console.log({ temp });
			const intValue = parseInt(temp.total_revenue);
			if (intValue > maxGraphValuePrevious) {
				maxGraphValuePrevious = intValue;
			}
			_g1.push(temp);
		}
		for (let i = 0; i < props.data2.length; i++) {
			let temp = props.data2[i];
			const intValue = parseInt(temp.total_revenue);
			if (intValue > maxGraphValueCurrent) {
				maxGraphValueCurrent = intValue;
			}
			_g2.push(temp);
		}

		maxGraphValueCurrent = maxGraphValueCurrent * 1.1;
		maxGraphValuePrevious = maxGraphValuePrevious * 1.1;
		setMaxValue(maxGraphValueCurrent > maxGraphValuePrevious ? maxGraphValueCurrent : maxGraphValuePrevious);
		setG1(_g1);
		setG2(_g2);
	}, [props.graph, props.graph2]);

	const isMobile = window.matchMedia('(max-width: 800px)').matches;

	const CustomTooltip = ({ active, payload, label }) => {
		if (active && payload && payload.length) {
			return (
				<div className='custom-tooltip'>
					<div className='custom-tooltip-date'>{`${label}`}</div>
					<div>
						{payload.map((pld) => (
							<div className='custom-tooltip-content'>
								<div>{pld.dataKey === 'total_revenue' ? 'Прибыль' : pld.dataKey === 'order_count' ? 'Покупки' : 'Экспаер'}</div>
								<div>
									{pld.value}
									{pld.dataKey === 'total_revenue' && '₽'}{' '}
								</div>
							</div>
						))}
					</div>
				</div>
			);
		}

		return null;
	};

	return (
		<>
			<div className='d-flex-gap'>
				<div>
					Purchases: ({props.days2} days): <b>{props.graph2 !== null ? props.graph2.count : 0}</b> / Revenue ({props.days2} days):{' '}
					<b>{props.graph2 !== null ? props.graph2.price : 0} RUB</b>
				</div>
				<div>
					Purchases: ({props.days} days): <b>{props.graph !== null ? props.graph.count : 0}</b> / Revenue ({props.days} days):{' '}
					<b>{props.graph !== null ? props.graph.price : 0} RUB</b>
				</div>
			</div>
			<div className='charts-grid'>
				<ResponsiveContainer className='custom-container'>
					<LineChart margin={{ top: 32, left: 32, right: 32, bottom: 32 }} width={isMobile ? 350 : 500} height={500} data={g2}>
						<YAxis hide={true} yAxisId='left-axis' domain={[0, maxValue]} orientation='left' dataKey='total_revenue' />
						{/* <YAxis hide={true} yAxisId='left-axis' domain={[0, 900]} orientation='left' dataKey='expiring_keys_count' /> */}
						<YAxis hide={true} yAxisId='right-axis' domain={[0, 900]} orientation='right' dataKey='order_count' />
						<Line dot={false} yAxisId='right-axis' name='Purchases: ' type='monotone' dataKey='order_count' stroke='#909090' />
						<Line dot={false} yAxisId='left-axis' name='Revenue: ' type='monotone' dataKey='total_revenue' stroke='#FFF' />
						<Line dot={false} yAxisId='right-axis' name='Expire: ' type='monotone' dataKey='expiring_keys_count' stroke='#FF0000' />
						<XAxis hide={true} name='OrderDate: ' dataKey='to_char' />
						<Tooltip content={<CustomTooltip />} />
					</LineChart>
				</ResponsiveContainer>
				<ResponsiveContainer className='custom-container'>
					<LineChart margin={{ top: 32, left: 32, right: 32, bottom: 32 }} width={isMobile ? 350 : 500} height={500} data={g1}>
						<YAxis hide={true} yAxisId='left-axis' domain={[0, maxValue]} orientation='left' dataKey='total_revenue' />
						<YAxis hide={true} yAxisId='right-axis' domain={[0, 900]} orientation='right' dataKey='order_count' />
						{/* <YAxis hide={true} yAxisId='left-axis' domain={[0, 900]} orientation='left' dataKey='expiring_keys_count' /> */}

						<Line dot={false} yAxisId='right-axis' name='Purchases: ' type='monotone' dataKey='order_count' stroke='#909090' />
						<Line dot={false} yAxisId='left-axis' name='Revenue: ' type='monotone' dataKey='total_revenue' stroke='#FFF' />
						<Line dot={false} yAxisId='right-axis' name='Expire: ' type='monotone' dataKey='expiring_keys_count' stroke='#FF0000' />
						<XAxis hide={true} name='OrderDate: ' dataKey='to_char' />

						<Tooltip content={<CustomTooltip />} />
					</LineChart>
				</ResponsiveContainer>
			</div>
		</>
	);
}
