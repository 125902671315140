import React, { useState, useEffect } from 'react';

import axios from 'axios';
import ServerRow from '../../components/ServerRow';
import ServerOverlay from '../../components/ServerOverlay';

export default function Proxies() {
	const API_URL = 'https://dusk.masliukov.com/v2-api/outline/';
	const [proxies, setProxies] = useState([]);

	useEffect(() => {
		getList();
	}, []);

	const getList = async () => {
		const CALL_URL = API_URL + 'getList';
		const request = await axios.post(CALL_URL);
		const _k = request.data.keys;
		const serversWithEnabledProxies = _k.filter((server) => server.is_proxy_enabled === true);

		const proxiesArray = [];
		serversWithEnabledProxies.forEach((element) => {
			const findExistingProxy = proxiesArray.find((proxy) => proxy.ip === element.proxy_ip);
			if (!findExistingProxy) {
				proxiesArray.push({
					ip: element.proxy_ip,
					servers: 1,
				});
			} else {
				findExistingProxy.servers++;
			}
		});

		console.log(proxiesArray);
		setProxies(proxiesArray);
	};

	const disableProxyByIp = async (ip) => {
		const CALL_URL = API_URL + 'disableProxy';
		const request = await axios.post(CALL_URL, { ip: ip });
		console.log({ result: request.data });
		getList();
	};

	return (
		<>
			<div className='content-block t-container'>
				<div className='t-content'>
					<div className='page-header'>
						<h1>Proxies</h1>
						<div className='header-buttons-group'></div>
					</div>
				</div>
				<div className='row-qiwi labels'>
					<div className='qiwi-column'>Status</div>
					<div className='qiwi-column'>Type</div>
					<div className='qiwi-column'>IP</div>
					<div className='qiwi-column'>Servers</div>
					<div className='qiwi-column'>Disable</div>
					<div className='qiwi-column'></div>
				</div>

				{proxies.map((proxy) => {
					return (
						<div key={proxy.id} className='row-qiwi'>
							<div className='qiwi-column'>
								<div className={'status-round purple'}></div>
							</div>
							<div className='qiwi-column'>PROXY</div>
							<div className='qiwi-column'>{proxy.ip}</div>
							<div className='qiwi-column'>{proxy.servers}</div>
							{/* <div className='qiwi-column wallet-hidden'>{server.api}</div> */}
							{/* <div className='qiwi-column'>
								{server.amountofusers} / {server.user_limit}
							</div> */}
							<div className='qiwi-column'>
								<button onClick={() => disableProxyByIp(proxy.ip)} className='t-button t-primary'>
									Disable
								</button>
							</div>
						</div>
					);
				})}
			</div>
		</>
	);
}
