import React, { useState, useEffect } from 'react';

import axios from 'axios';

export default function SettingsQiwi() {
  const API_URL = 'https://dusk.masliukov.com/v2-api/qiwi/';
  const [newKey, setNewKey] = useState('');
  const [keys, setKeys] = useState([]);
  const [selectedTag, setSelectedTag] = useState('default');
  const [stats, setStats] = useState([]);

  const validateKey = (string = '') => {
    const cleanedString = string.trim();
    setNewKey(cleanedString);
  };

  useEffect(() => {
    getList();
  }, []);

  const toggleKey = async (event) => {
    const id = event.currentTarget.dataset.idx;
    const status = event.currentTarget.dataset.status;
    let URL = '';
    // console.log(status);
    if (status === 'true') {
      URL = API_URL + 'disableKey';
    } else {
      URL = API_URL + 'enableKey';
    }

    const data = {
      id: id,
    };
    // console.log(URL);
    const request = await axios.post(URL, data);
    // console.log(request);
    if (request.data.ok) {
      getList();
    }
  };

  const getList = async () => {
    const CALL_URL = API_URL + 'getList';
    const request = await axios.post(CALL_URL);
    // console.log(request.data.keys.keys);
    setKeys(request.data.keys.keys);
    setStats(request.data.keys.stats);
  };

  const createKey = async () => {
    if (newKey.length < 10) {
      return alert('Qiwi key is not valid');
    }
    const CALL_URL = API_URL + 'createKey';
    const data = {
      wallet: newKey,
      tag: selectedTag,
    };

    const request = await axios.post(CALL_URL, data);
    if (request.data.ok === true) {
      getList();
      setNewKey('');
    }
  };

  const findStatsById = (id) => {
    let _stats = stats;
    for (let i = 0; i < _stats.length; i++) {
      // console.log(_stats[i].id);
      if (_stats[i].qiwi_id === id) {
        return _stats[i].count;
      }
    }
  };

  const deleteKey = async (event) => {
    // console.log(event.currentTarget.dataset.idx);
    const CALL_URL = API_URL + 'deleteKey';
    const data = {
      id: event.currentTarget.dataset.idx,
    };
    const request = await axios.post(CALL_URL, data);
    // console.log(request);
    if (request.data.ok) {
      getList();
    }
  };

  return (
    <div className="content-block">
      <h1>QIWI wallets</h1>
      <div className="create-qiwi-group">
        <input className="d-input" type="text" onChange={(e) => validateKey(e.target.value)}></input>
        <select className="d-input" value={selectedTag} onChange={(e) => setSelectedTag(e.target.value)}>
          <option value="default">VIP</option>
          <option value="premium">Когорта Премиум</option>
          <option value="referrer">referrer</option>
        </select>
        <button onClick={createKey} className="d-button">
          Добавить
        </button>
      </div>
      <h3>Wallets list</h3>
      <div className="row-qiwi">
        <div className="qiwi-column">Status</div>
        <div className="qiwi-column">Date</div>
        <div className="qiwi-column">Tag</div>
        <div className="qiwi-column">ID</div>
        <div className="qiwi-column">Key</div>
        <div className="qiwi-column">24h</div>
        <div className="qiwi-column">Toggle</div>
        <div className="qiwi-column">Delete</div>
      </div>
      {keys.map((wallet) => {
        return (
          <div className="row-qiwi" style={{ opacity: wallet.frozen ? '0.3' : '1' }}>
            <div className="qiwi-column">
              <div className={wallet.enabled ? 'status-round green' : 'status-round red'}></div>
            </div>
            <div className="qiwi-column">{wallet.created_at.split('T')[0]}</div>
            <div className="qiwi-column">{wallet.tag === 'default' ? 'pseudo-vip' : wallet.tag}</div>
            <div className="qiwi-column">{wallet.id}</div>
            <div className="qiwi-column wallet-hidden">{wallet.wallet}</div>

            <div className="qiwi-column">{findStatsById(wallet.id)}</div>
            <div className="qiwi-column">
              <button onClick={toggleKey} data-status={wallet.enabled} data-idx={wallet.id} className="d-button">
                {wallet.enabled ? 'Disable' : 'Enable'}
              </button>
            </div>
            <div className="qiwi-column">
              <button onClick={deleteKey} data-idx={wallet.id} className="d-button">
                Delete key
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
}
