import { useState } from 'react';

import axios from 'axios';
import Loader from './helpers/Loader';

export default function ServerCreationOverlay({ close, getList }) {
	const API_URL = 'https://dusk.masliukov.com/v2-api/outline/';
	const [isLoading, setLoading] = useState(false);
	const createKey = async () => {
		if (newKey.length < 10) {
			return alert('Qiwi key is not valid');
		}
		setLoading(true);
		const CALL_URL = API_URL + 'createKey';
		const data = {
			api: newKey,
			plan: newPlan,
		};
		const request = await axios.post(CALL_URL, data);
		if (request.data.ok === true) {
			getList();
			setLoading(false);
			close();
		}
	};
	const [newKey, setNewKey] = useState('');
	const [newPlan, setPlan] = useState(0);

	const validateKey = (string = '') => {
		const cleanedString = string.trim();
		setNewKey(cleanedString);
	};

	const handleClose = (event) => {
		const { id } = event.target;
		if (id === 'overlay') {
			close();
		}
	};

	return (
		<div className='overlay' id='overlay' onClick={handleClose}>
			<div className='overlay-content overlay-content-server'>
				<div>Добавить сервер</div>
				<input className='t-input' placeholder='IP adress' type='text' value={newKey} onChange={(e) => validateKey(e.target.value)}></input>
				<select className='t-input' type='text' value={newPlan} onChange={(e) => setPlan(e.target.value)}>
					<option value={0}>Base</option>
					<option value={1}>Premium</option>
					<option value={2}>Deeplink</option>
					<option value={3}>Family</option>
					<option value={15}>Family 1y</option>
					<option value={4}>1 Month / UK 🇬🇧</option>
					<option value={5}>1 Month / Finland 🇫🇮</option>
					<option value={6}>1 Month / Germany 🇩🇪</option>
					<option value={10}>1 Month / USA 🇺🇸</option>
					<option value={12}>1 Month / Rus 🇷🇺</option>
					<option value={7}>1 Month / Yota, Tele2</option>
					<option value={8}>6 Month / 🇬🇧 UK</option>
					<option value={9}>6 Month / 🇫🇮 Finland</option>
					<option value={13}>6 Month / 🇩🇪 Germany</option>
					<option value={14}>6 Month / 🇺🇸 USA</option>
					<option value={11}>6 Month / Yota, Tele2</option>
					<option value={20}>1 Month / Я в Китае 🇨🇳</option>
					<option value={21}>6 Month / Я в Китае 🇨🇳</option>
					<option value={99}>Trial (.99)</option>
				</select>
				<button onClick={createKey} className='t-button t-primary'>
					{isLoading ? <Loader /> : 'Добавить'}
				</button>
			</div>
		</div>
	);
}
